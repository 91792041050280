/* PC (해상도 1025px)*/
@media all and (min-width:1025px) {
    .swipper_area {
        display: flex;
        width: 100%;
        height: 900px;
        justify-content: center;
        position: relative;
        overflow: hidden;
    }

    .swipper_item_background {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: cover;
        transform: scale(1.1) rotate(-0.01deg);
        transition: 0.3s;
        opacity: 1;
        animation: fadeInBg 10s linear both;
    }

    .swipper_item_background:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .swipper_item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    @keyframes fadeInBg {
        0% {
            transform: scale(1.2) rotate(-0.01deg);
        }

        100% {
            transform: scale(1) rotate(-0.01deg);
        }
    }

    .swipper_contents {
        display: flex;
        flex-direction: column;
        width: 75%;
        margin-top: 300px;
    }

    .swipper_title {
        font-family: LibreBaskerville;
        font-size: 60px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.29px;
        text-align: left;
        color: #fff;
    }

    .swipper_explanation {
        display: flex;
        align-items: center;
        width: 97%;
        justify-content: space-between;
    }

    .swipper_text {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .swipper_text_item {
        width: 100%;
        display: flex;
        font-family: Pretendard;
        font-size: 28px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;

        letter-spacing: normal;
        text-align: left;
        color: #fff;
        align-items: center;
        gap: 31px;
    }

    .swipper_text_item:first-child {
        margin-left: 142px;
        width: calc(100% - 142px);
        line-height: 1.07;
    }

    .swipper_text_item:last-child {
        margin-left: 387px;
        width: calc(100% - 387px);
        line-height: 1.25;
    }

    .swipper_border {
        display: flex;
        width: 90px;
        height: 1px;
        background-color: #ffffff;
    }

    .rotate_item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        transition: all 0.5s ease;
        /* 테스트 */
        /* width: 196px;
        height: 196px; */
    }

    .rotate_item:hover {
        transform: scale(1.1);
    }

    .rotate_img {
        animation: rotate_image 10s linear infinite;
        transform-origin: 50% 50%;

        /* test */
        /* width: 100%;
        height: 100%; */
    }

    .arrow_img {
        position: absolute;
        width: auto !important;
        height: auto !important;
    }

    @keyframes rotate_image {
        100% {
            transform: rotate(360deg);
        }
    }

    .counting_area_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100% - 200px);
        /* height: 353px; */
        padding: 114px 100px 41px;
        gap: 60px;
        background-color: #F2F6FF;
        justify-content: center;
        /* align-items: flex-end; */
    }

    .counting_area {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .text_description {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
    }

    .count_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 49px;
    }

    .count_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 23px;
    }

    .count_value {
        font-family: LibreBaskerville;
        font-size: 55px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        /* line-height: 0.51; */
        letter-spacing: -0.28px;
        text-align: center;
        color: #1f299c;
    }

    .count_title {
        font-family: Pretendard;
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        /* line-height: 1.17; */
        letter-spacing: -0.12px;
        text-align: center;
        color: #1f299c;
    }

    .count_text {
        font-family: Pretendard;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.87;
        letter-spacing: -0.08px;
        text-align: center;
        color: rgba(31, 41, 156, 0.8);
    }

    .webinar_area {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100% - 228px);
        /* height: 995px; */
        border-bottom: 1px solid #1f299c;
        padding: 44px 114px 0;
        gap: 45px;
    }

    .webinar_title {
        font-family: LibreBaskerville;
        font-size: 60px;
        height: 66px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.6px;
        color: #1f299c;
    }

    .webinar_content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        height: 100%;
    }

    .webinar_register_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #1f299c;
        /* width: calc(50% - 62px); */
        /* height: 100%; */
        padding-right: 62px;
    }

    .webinar_register_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .webinar_register_item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        position: relative;
    }

    .webinar_register_label {
        font-family: Pretendard;
        font-size: 23px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.23px;
        text-align: left;
        color: #f15928;
        line-height: 23px;
        margin-top: 27px;
        margin-bottom: 36px;
    }

    .webinar_register_title {
        font-family: LibreBaskerville;
        font-size: 47px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
        line-height: 53px;
        margin-bottom: 32px;
    }

    .webinar_register_title>span {
        font-weight: bold;
    }

    .webinar_register_text {
        font-family: Pretendard;
        font-size: 19px;
        line-height: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
        margin-bottom: 39px;
    }

    .webinar_register_img {
        width: 100%;
    }

    .webinar_view_more_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 251px;
        height: 53px;
        border: solid 1px #a5a5a5;
        background-color: #ffffff;
        font-family: Pretendard;
        font-size: 19px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #222;
        margin: 50px 0 18.5px;
        cursor: pointer;
        transition: all 0.5s;
    }

    .webinar_view_more_btn:hover {
        background-color: #1f299c;
        color: #ffffff;
    }

    .webinar_library_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* width: calc(50% - 50px);
        height: 100%; */
        padding-left: 50px;
    }

    .webinar_library_label {
        font-family: Pretendard;
        font-size: 23px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.23px;
        text-align: left;
        color: #f15928;
        line-height: 23px;
        margin-top: 27px;
        margin-bottom: 9px;
    }

    .webinar_library_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .webinar_library_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 175px;
        border-bottom: 1px solid #1f299c;
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .webinar_library_item:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    .webinar_library_img_skeleton {
        width: 177px;
        height: 175px;
    }

    .webinar_library_info_wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        height: 100%;
    }

    .webinar_library_info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: calc(100% - 31px);
        padding: 19px 0 12px;
    }

    .webinar_library_info_text {
        display: flex;
        flex-direction: column;
        gap: 19px;
    }

    .webinar_library_index {
        font-family: Pretendard;
        font-size: 30px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 23px;
        letter-spacing: normal;
        color: #1f299c;
    }

    .webinar_library_title {
        font-family: Pretendard;
        font-size: 23px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 22px;
        letter-spacing: normal;
        color: #1f299c;
    }

    .webinar_library_datetime {
        font-family: Pretendard;
        font-size: 19px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 18px;
        letter-spacing: normal;
        color: #1f299c;
    }

    .webinar_library_btn_wrap {
        display: flex;
        align-items: flex-end;
        height: 100%;
    }

    .webinar_library_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 57px;
        height: 57px;
        border: solid 1px #c0c0c0;
        border-radius: 50%;
        cursor: pointer;
    }

    .about_jam_area {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .about_jam_preview {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: calc(100% - 228px);
        /* height: 696px; */
        padding: 86px 114px 98px;
    }

    .about_jam_preview_text_wrap {
        padding-top: 52px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: calc(50% - 125px);
        padding-right: 125px;
    }

    .about_jam_preview_label_wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 19px;
        margin-bottom: 68px;
    }

    .about_jam_preview_border {
        width: 90px;
        height: 1px;
        background-color: #1f299c;
    }

    .about_jam_preview_label {
        font-family: Pretendard;
        font-size: 19px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 15px;
        letter-spacing: normal;
        color: #1f299c;
    }

    .about_jam_preview_title {
        font-family: LibreBaskerville;
        font-size: 65px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.65px;
        text-align: left;
        color: #1f299c;
        line-height: 72px;
    }

    .about_jam_preview_title:last-child {
        font-size: 50px;
        letter-spacing: -0.5px;
        line-height: 55px;
        margin-left: 104px;
    }

    .about_jam_preview_text_item {
        display: flex;
        width: 100%;
        justify-content: space-around;
        padding-left: 78px;
    }

    .about_jam_preview_text {
        margin-top: 105px;
        font-family: Pretendard;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
        height: 66px;
        line-height: 40px;
    }

    .about_jam_preview_text>div:first-child {
        text-align: left;
    }

    .about_jam_preview_text>div:last-child {
        text-align: right;
    }

    .about_jam_preview_text>div>span {
        font-weight: 600;
    }

    .about_jam_preview_img_wrap {
        display: flex;
        align-items: flex-end;
        width: calc(50% - 125px);
        position: relative;
        padding-left: 125px;
    }

    .about_jam_preview_img {
        width: 100%;
    }

    .about_jam_preview_img_wrap .rotate_item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        left: -36px;
        z-index: 2;
        transition: none;
        bottom: 40px;
        /* transition: all 0.5s ease; */
    }

    .about_jam_description {
        display: flex;
        align-items: center;
        /* align-items: center;
        justify-content: center; */
        width: calc(100% - 228px);
        /* height: 720px; */
        padding: 92px 114px 93px;
        background: #19249D;
        gap: 115px;
    }

    .about_jam_description_img_wrap {
        display: flex;
        align-items: flex-end;
        max-width: 720px;
    }

    .about_jam_description_img {
        max-width: 720px;
        width: 50%;
    }

    .about_jam_description_wrap {
        display: flex;
        flex-direction: column;
        width: 50%;
        gap: 80px;
    }

    .about_jam_description_content {
        display: flex;
        flex-direction: column;
        gap: 40px;
        /* max-width: 750px; */
    }

    .about_jam_description_item {
        font-family: Pretendard;
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.7;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }

    .about_jam_description_summary_content {
        padding-left: 60px;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .about_jam_description_summary_item {
        display: flex;
        gap: 12px;
    }

    .about_jam_description_summary_label {
        width: 13px;
        height: 13px;
        background-color: #f15928;
        border-radius: 50%;
    }

    .about_jam_description_summary_text_wrap {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 18px;
    }

    .about_jam_description_summary_text {
        font-family: LibreBaskerville;
        font-size: 23px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        white-space: pre-line;
    }

    .products_area {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: calc(100% - 228px);
        /* height: 713px; */
        background-color: #F5F5F5;
        padding: 97px 114px 90px;
        gap: 86px;
    }

    .products_title_wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 35px;
    }

    .products_title {
        font-family: LibreBaskerville;
        font-size: 60px;
        height: 51px;
        line-height: 51px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.6px;
        text-align: left;
        color: #1f299c;
    }

    .products_explanation {
        padding-left: 73px;
        display: flex;
        align-items: center;
        gap: 19px;
    }

    .products_explanation>div {
        width: 90px;
        height: 1px;
        background-color: #1f299c;
    }

    .products_explanation>span {
        height: 19px;
        font-family: Pretendard;
        font-size: 19px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 19px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .products_area :global .swiper {
        width: calc(100% - 86px) !important;
        height: 100% !important;
    }

    .product_swiper_area {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        gap: 25px;
    }

    .product_swiper_prev_btn,
    .product_swiper_next_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 57px;
        height: 57px;
        border: solid 1px #c0c0c0;
        border-radius: 50%;
        cursor: pointer;
    }

    .product_swiper_prev_btn>img {
        transform: rotate(180deg);
    }

    .product_swiper_item_img_wrapper {
        display: flex;
        width: 100%;
        height: calc(100% - 93px);
        justify-content: center;
        position: relative;
        overflow: hidden;
    }

    .product_swiper_item_img {
        width: 100%;
        transition: all 0.5s ease;
    }

    .product_swiper_item_img:hover {
        transform: scale(1.1);
    }

    .product_swiper_item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 40px;
        cursor: pointer;
    }

    .product_swiper_item_text {
        display: flex;
        flex-direction: column;
        gap: 18px;
        align-items: center;
        justify-content: center;
    }

    .product_swiper_name {
        /* height: 21px;
        line-height: 21px; */
        font-family: Pretendard;
        font-size: 26px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #1f299c;
    }

    .product_swiper_explain {
        height: 15px;
        line-height: 15px;
        font-family: Pretendard;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #1f299c;
    }

    .newsletter_area_wrapper {
        width: 100%;
        height: 300vh;
        position: relative;
    }

    .newsletter_area {
        /* position: sticky;
        top: 0; */
        /* height: 100vh; */
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: calc(100% - 113px);
        padding: 151px 0px 105px 113px;
        gap: 111px;
    }

    .newsletter_area :global .swiper-wrapper {
        width: auto;
    }

    .newsletter_area .newsletter_swiper_slide {
        width: auto !important;
    }

    .newsletter_title_wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 35px;
        /* padding: 150px 0; */
    }

    .newsletter_title {
        height: 51px;
        font-family: LibreBaskerville;
        font-size: 60px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 51px;
        letter-spacing: -0.6px;
        text-align: left;
        color: #f15928;
    }

    .newsletter_explanation {
        padding-left: 2px;
        display: flex;
        /* align-items: center; */
        gap: 19px;
    }

    .newsletter_explanation>div {
        width: 90px;
        height: 1px;
        background-color: #f15928;
        margin-top: 15px;
    }

    .newsletter_explanation>span {
        width: 306px;
        font-family: Pretendard;
        font-size: 19px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.58;
        letter-spacing: normal;
        text-align: left;
        color: #f15928;
    }

    .newsletter_viewall {
        display: flex;
        align-items: center;
        gap: 17px;
        margin-top: 22px;
        margin-left: 112px;
    }

    .newsletter_viewall>span {
        height: 15px;
        font-family: Pretendard;
        font-size: 19px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 15px;
        letter-spacing: normal;
        text-align: left;
        color: #f15928;
    }

    .newsletter_viewall_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 57px;
        height: 57px;
        border: solid 1px #f15928;
        /* background-color: #c0c0c0; */
        border-radius: 50%;
    }

    .newletter_list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: hidden;
    }

    .newsletter_list_container {
        width: 100%;
        height: 300vh;
        padding: 0 16px;
    }

    .newsletter_list_wrapper {
        /* position: sticky;
        top: 0; */
        /* height: 100vh; */
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: hidden;
        /* padding: 150px 0; */
    }

    .newsletter_list_carousel {
        display: flex;
        gap: 48px;
    }

    .newsletter_item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: hidden;
        gap: 19px;
        max-width: 442px;
    }

    .newsletter_img_wrapper {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
    }

    .newsletter_img {
        transition: all 0.5s ease;
    }

    .newsletter_img:hover {
        transform: scale(1.1);
    }

    .newsletter_item_title {
        /* height: 15px; */
        font-family: Pretendard;
        font-size: 21px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        /* line-height: 15px; */
        letter-spacing: normal;
        text-align: left;
        color: #f15928;
    }

    .newsletter_item_text {
        /* height: 17px; */
        font-family: Pretendard;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.41;
        letter-spacing: -0.09px;
        text-align: left;
        color: #1f299c;
    }

    /* 움직이는 텍스트 */
    .slideText_area {
        /* display: flex;
        align-items: center;
        justify-content: flex-start; */
        height: 86px;
        background-color: #f15928;

        font-size: 60px;
        font-family: 'Raleway', Sans-serif;
        font-weight: 300;
        position: relative;
        width: 100%;
        max-width: 100%;
        /* height: auto;
        padding: 100px 0; */
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .slide_list {
        position: absolute;
        white-space: nowrap;
        will-change: transform;
        animation: marquee 60s linear infinite;
        /* width: 100%; */
        height: 100%;
    }

    @keyframes marquee {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(-50%);
        }
    }

    .slideText_swiper {
        width: 100%;
        height: 100%;
    }

    .slide_list_content {
        display: flex !important;
        height: 100%;
        align-items: center;
        gap: 50px;
    }

    .slide_text {
        font-family: Pretendard;
        font-size: 23px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #fff;
        position: relative;
    }

    .slide_middot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #fff;
    }


    .float_btn_area {
        padding: 13px;
        border-radius: 39px;
        background-color: #1f299c;
    }

    .float_btn_area .float_root_btn {
        display: none;
    }

    .float_btn_area :global .ant-float-btn-group-wrap {
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
        gap: 32px;
    }

    .floating_btn_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }

    .floating_btn_item:last-child {
        margin-bottom: 12px;
    }

    .floating_btn {
        width: 51px;
        height: 51px;
        background-color: rgba(255, 255, 255, 0.17);
        margin-bottom: 0px !important;
    }

    .floating_btn :global .ant-float-btn-body {
        background-color: rgba(255, 255, 255, 0.17);
    }

    .floating_btn :global .ant-float-btn-content {
        width: 100% !important;
    }

    .floating_btn :global .ant-float-btn-icon {
        margin: 0 !important;
        width: 100% !important;
        display: flex;
        justify-content: center;
    }

    .floating_btn_label {
        height: 14px;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
    }
}

/* PC (해상도 1920px)*/
@media all and (min-width:1920px) {
    .about_jam_description {
        display: flex;
        /* align-items: center;
        justify-content: center; */
        width: calc(100% - 335px);
        /* height: 720px; */
        padding: 92px 221px 93px 114px;
        background: #19249D;
        gap: 110px;
    }
}

/* @media (hover: hover) and (min-width: 1023px) {
    .slide_list_content {
        -webkit-transform: translateY(calc(100% - 8rem));
        transform: translateY(calc(100% - 8rem));
    }
} */

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1024px)*/
@media all and (min-width:768px) and (max-width:1024px) {
    .swipper_area {
        display: flex;
        width: 100%;
        height: 480px;
        justify-content: center;
        position: relative;
        overflow: hidden;
    }

    .swipper_item_background {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: cover;
        transform: scale(1.1) rotate(-0.01deg);
        transition: 0.3s;
        opacity: 1;
        animation: fadeInBg 10s linear both;
    }

    .swipper_item_background:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .swipper_item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    @keyframes fadeInBg {
        0% {
            transform: scale(1.2) rotate(-0.01deg);
        }

        100% {
            transform: scale(1) rotate(-0.01deg);
        }
    }

    .swipper_contents {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin-top: 275px;
        gap: 7.2px;
    }

    .swipper_title {
        font-family: LibreBaskerville;
        font-size: 34px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 42px;
        height: 42px;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }

    .swipper_explanation {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    .swipper_text {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .swipper_text_item {
        width: 100%;
        display: flex;
        font-size: 17px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.41;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        align-items: center;
        gap: 31px;
    }

    .swipper_text_item:first-child {
        margin-left: 85.5px;
        width: calc(100% - 85.5px);
        line-height: 1.07;
    }

    .swipper_text_item:last-child {
        margin-left: 233px;
        width: calc(100% - 233px);
        line-height: 1.25;
    }

    .swipper_border {
        display: flex;
        width: 55px;
        height: 1px;
        background-color: #ffffff;
    }

    .rotate_item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        transition: all 0.5s ease;
    }

    .rotate_item:hover {
        transform: scale(1.1);
    }

    .rotate_img {
        animation: rotate_image 10s linear infinite;
        transform-origin: 50% 50%;
        width: 100px;
    }

    .arrow_img {
        position: absolute;
        width: 26.4px;
        height: 15.1px;
    }

    @keyframes rotate_image {
        100% {
            transform: rotate(360deg);
        }
    }


    .counting_area_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 40px 0;
        gap: 20px;
        /* height: 353px; */
        background-color: #F2F6FF;
        justify-content: center;
        /* align-items: flex-end; */
    }

    .counting_area {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .text_description {
        width: calc(100% - 20px);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 10px;
        padding-right: 20px;
    }


    .count_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 20px;
    }

    .count_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;
    }

    .count_value {
        font-family: LibreBaskerville;
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        /* height: 40px;
        line-height: 40px; */
        letter-spacing: normal;
        text-align: center;
        color: #1f299c;
    }

    .count_title {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        /* height: 16px;
        line-height: 16px; */
        letter-spacing: normal;
        text-align: center;
        color: #1f299c;
    }

    .count_text {
        font-family: Pretendard;
        font-size: 11px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        height: 28px;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: center;
        color: #434ba9;
        white-space: pre-line;
    }

    .webinar_area {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100% - 120px);
        /* height: 995px; */
        border-bottom: 1px solid #1f299c;
        padding: 44px 60px 0;
        gap: 21.5px;
    }

    .webinar_title {
        font-family: LibreBaskerville;
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        height: 40px;
        line-height: 40px;
        letter-spacing: normal;
        text-align: center;
        color: #1f299c;
    }

    .webinar_content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        height: 100%;
    }

    .webinar_register_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #1f299c;
        /* width: calc(50% - 34.5px);
        height: 100%; */
        padding-right: 34.5px;
    }

    .webinar_register_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .webinar_register_item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
    }

    .webinar_register_label {
        font-family: Pretendard;
        font-size: 17px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        height: 20px;
        line-height: 20px;
        letter-spacing: normal;
        text-align: left;
        color: #f15928;
        margin-top: 12.5px;
        margin-bottom: 25px;
    }

    .webinar_register_title {
        font-family: LibreBaskerville;
        font-size: 25px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        height: 31px;
        line-height: 31px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
        margin-bottom: 12px;
    }

    .webinar_register_title>span {
        font-weight: bold;
    }

    .webinar_register_text {
        font-family: Pretendard;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        height: 14px;
        line-height: 14px;
        letter-spacing: -0.06px;
        text-align: left;
        color: #1f299c;
        margin-bottom: 34.6px;
    }

    .webinar_register_img {
        width: 100%;
    }

    .webinar_view_more_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 135px;
        height: 37px;
        border: solid 1px #a5a5a5;
        background-color: #ffffff;
        font-family: Pretendard;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #111;
        margin: 13px 0 18.5px;
        cursor: pointer;
        transition: all 0.5s;
    }

    .webinar_view_more_btn:hover {
        background-color: #1f299c;
        color: #ffffff;
    }

    .webinar_library_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* width: calc(50% - 27px);
        height: 100%; */
        padding-left: 27px;
    }

    .webinar_library_label {
        font-family: Pretendard;
        font-size: 17px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        height: 20px;
        line-height: 20px;
        letter-spacing: normal;
        text-align: left;
        color: #f15928;
        margin-top: 12.5px;
        margin-bottom: 1.5px;
    }

    .webinar_library_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .webinar_library_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 94px;
        border-bottom: 1px solid #1f299c;
        padding-top: 12.5px;
        padding-bottom: 12.5px;
    }

    .webinar_library_item:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    .webinar_library_img_skeleton {
        width: 94px;
        height: 94px;
    }

    .webinar_library_info_wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 23px;
        height: 100%;
    }

    .webinar_library_info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: calc(100% - 10px);
        padding: 4px 0 6px;
    }

    .webinar_library_info_text {
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    .webinar_library_index {
        font-family: Pretendard;
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        height: 21px;
        line-height: 21px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .webinar_library_title {
        font-family: Pretendard;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        height: 18px;
        line-height: 18px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .webinar_library_datetime {
        font-family: Pretendard;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        height: 14px;
        line-height: 14px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .webinar_library_btn_wrap {
        display: flex;
        align-items: flex-end;
        height: 100%;
    }

    .webinar_library_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border: solid 1px #c0c0c0;
        border-radius: 50%;
        cursor: pointer;
    }

    .webinar_library_btn>img {
        width: 12.5px;
        height: 11.8px;
    }

    .about_jam_area {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .about_jam_preview {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: calc(100% - 120px);
        padding: 46px 60px 46px;
    }

    .about_jam_preview_text_wrap {
        padding-top: 25px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: calc(50% - 34px);
        height: 100%;
        padding-right: 34px;
    }

    .about_jam_preview_label_wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin-bottom: 27px;
    }

    .about_jam_preview_border {
        width: 48px;
        height: 1px;
        background-color: #1f299c;
    }

    .about_jam_preview_label {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        height: 16px;
        line-height: 16px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .about_jam_preview_title {
        font-family: LibreBaskerville;
        font-size: 34px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        height: 42px;
        line-height: 42px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .about_jam_preview_title:last-child {
        font-size: 26px;
        height: 32px;
        line-height: 32px;
        margin-left: 56px;
    }

    .about_jam_preview_text_item {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    .about_jam_preview_text {
        margin-top: 56px;
        font-family: Pretendard;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.41;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
        height: 44px;
    }

    .about_jam_preview_text>div:first-child {
        text-align: left;
    }

    .about_jam_preview_text>div:last-child {
        text-align: right;
    }

    .about_jam_preview_text>div>span {
        font-weight: 600;
    }

    .about_jam_preview_img_wrap {
        display: flex;
        align-items: flex-end;
        width: calc(50% - 67px);
        position: relative;
        padding-left: 67px;
    }

    .about_jam_preview_img {
        width: 100%;
    }

    .about_jam_preview_img_wrap .rotate_item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        left: 0;
        z-index: 2;
    }


    .about_jam_description {
        display: flex;
        align-items: center;
        width: calc(100% - 120px);
        padding: 49px 60px 48px;
        background: #19249D;
        gap: 61px;
    }

    .about_jam_description_img {
        width: 50%;
    }

    .about_jam_description_wrap {
        display: flex;
        flex-direction: column;
        gap: 41px;
        width: 50%;
    }

    .about_jam_description_content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        /* max-width: 750px; */
    }

    .about_jam_description_item {
        font-family: Pretendard;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }

    .about_jam_description_summary_content {
        padding-left: 32px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .about_jam_description_summary_item {
        display: flex;
        gap: 12px;
    }

    .about_jam_description_summary_label {
        width: 9px;
        height: 9px;
        background-color: #f15928;
        border-radius: 50%;
    }

    .about_jam_description_summary_text_wrap {
        display: flex;
        flex-direction: column;
        margin-top: 6px;
    }

    .about_jam_description_summary_text {
        font-family: LibreBaskerville;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        white-space: pre-line;
    }

    .products_area {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: calc(100% - 120px);
        background-color: #F5F5F5;
        padding: 51px 60px 62px;
        gap: 40px;
    }

    .products_title_wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 35px;
    }

    .products_title {
        font-family: LibreBaskerville;
        font-size: 31px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        height: 38px;
        line-height: 38px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .products_explanation {
        padding-left: 39px;
        display: flex;
        align-items: center;
        gap: 10.5px;
    }

    .products_explanation>div {
        width: 48px;
        height: 1px;
        background-color: #1f299c;
    }

    .products_explanation>span {
        height: 16px;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 16px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .products_area :global .swiper {
        width: calc(100% - 114px) !important;
        height: 100% !important;
    }

    .product_swiper_area {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        gap: 13px;
    }

    .product_swiper_prev_btn,
    .product_swiper_next_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border: solid 1px #c0c0c0;
        border-radius: 50%;
        cursor: pointer;
    }

    .product_swiper_prev_btn>img,
    .product_swiper_next_btn>img {
        width: 12.5px;
        height: 11.8px;

    }

    .product_swiper_prev_btn>img {
        transform: rotate(180deg);
    }

    .product_swiper_item_img_wrapper {
        display: flex;
        width: 100%;
        height: calc(100% - 54px);
        justify-content: center;
        position: relative;
        overflow: hidden;
    }

    .product_swiper_item_img {
        width: 100%;
        transition: all 0.5s ease;
    }

    .product_swiper_item_img:hover {
        transform: scale(1.1);
    }


    .product_swiper_item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 13px;
        cursor: pointer;
    }

    .product_swiper_item_text {
        display: flex;
        flex-direction: column;
        gap: 7px;
        align-items: center;
        justify-content: center;
    }

    .product_swiper_name {
        font-family: Pretendard;
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        height: 21px;
        line-height: 21px;
        letter-spacing: normal;
        text-align: center;
        color: #1f299c;
    }

    .product_swiper_explain {
        height: 13px;
        line-height: 13px;
        font-family: Pretendard;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #1f299c;
    }

    .newsletter_area {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: calc(100% - 61px);
        height: 230px;
        padding: 81px 0px 61px 60px;
        gap: 60.5px;
    }

    .newsletter_area :global .swiper-wrapper {
        width: auto;
    }

    .newsletter_area .newsletter_swiper_slide {
        width: auto !important;
    }

    .newsletter_title_wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;
    }

    .newsletter_title {
        font-family: LibreBaskerville;
        font-size: 31px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        height: 38px;
        line-height: 38px;
        letter-spacing: normal;
        text-align: left;
        color: #f15928;
    }

    .newsletter_explanation {
        display: flex;
        /* align-items: center; */
        gap: 10px;
    }

    .newsletter_explanation>div {
        width: 48px;
        height: 1px;
        background-color: #f15928;
        margin-top: 8.5px;
    }

    .newsletter_explanation>span {
        width: 163px;
        height: 73px;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: left;
        color: #f15928;
    }

    .newsletter_viewall {
        display: flex;
        align-items: center;
        gap: 6px;
        margin-top: 21px;
        margin-left: 59px;
    }

    .newsletter_viewall>span {
        font-family: Pretendard;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        height: 14px;
        line-height: 14px;
        letter-spacing: normal;
        text-align: left;
        color: #f15928;
    }

    .newsletter_viewall_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border: solid 1px #f15928;
        /* background-color: #c0c0c0; */
        border-radius: 50%;
    }

    .newsletter_viewall_btn>img {
        width: 12.5px;
        height: 11.8px;
    }

    .newletter_list {
        position: sticky;
        top: 0;
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: hidden;
    }

    .newsletter_list_wrapper {
        display: flex;
        gap: 23px;
    }

    .newsletter_item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: hidden;
        gap: 5px;
        max-width: 235px;
    }

    .newsletter_img_wrapper {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
    }

    .newsletter_img {
        width: 100%;
        transition: all 0.5s ease;
    }

    .newsletter_img:hover {
        transform: scale(1.1);
    }

    .newsletter_item_title {
        height: 18px;
        font-family: Pretendard;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 18px;
        letter-spacing: normal;
        text-align: left;
        color: #f15928;
    }

    .newsletter_item_text {
        font-family: Pretendard;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.46;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    /* 움직이는 텍스트 */
    .slideText_area {
        /* display: flex;
        align-items: center;
        justify-content: flex-start; */
        height: 63px;
        background-color: #f15928;

        font-size: 60px;
        font-family: 'Raleway', Sans-serif;
        font-weight: 300;
        position: relative;
        width: 100%;
        max-width: 100%;
        /* height: auto;
        padding: 100px 0; */
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .slide_list {
        position: absolute;
        white-space: nowrap;
        will-change: transform;
        animation: marquee 60s linear infinite;
        /* width: 100%; */
        height: 100%;
    }

    @keyframes marquee {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(-50%);
        }
    }

    .slideText_swiper {
        width: 100%;
        height: 100%;
    }

    .slide_list_content {
        display: flex !important;
        height: 100%;
        align-items: center;
        gap: 28px;
    }

    .slide_text {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        position: relative;
    }

    .slide_middot {
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: #fff;
    }

    .footer {
        display: flex;
        width: 100%;
        /* height: 370px */
    }

    .footer_logo {
        display: flex;
        justify-content: center;
        width: 165px;
        border-right: 1px solid #1f299c;
        padding-top: 29px;
    }

    .footer_wrap {
        display: flex;
        flex-direction: column;
        width: calc(100% - 166px);
        height: 100%;
    }

    .footer_content {
        display: flex;
        width: 100%;
        height: calc(100% - 67px);
    }

    .footer_contactus {
        display: flex;
        flex-direction: column;
        border-right: 1px solid #1f299c;
        padding: 29px 21px 0px;
        gap: 12.5px
    }

    .footer_contactus_text {
        height: 21px;
        font-family: Pretendard;
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 21px;
        letter-spacing: normal;
        color: #1f299c;
    }

    .footer_contactus_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 280px;
        height: 43px;
        background-color: #1f299c;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        cursor: pointer;
    }

    .footer_contactus_btn>span {
        font-weight: normal;
    }

    .footer_sitemap {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 30px 35px 67px;
        /* gap: 61px; */
    }

    .footer_sitemap_menu {
        display: flex;
        flex-direction: column;
        gap: 22px;
    }

    .footer_sitemap_title {
        height: 13px;
        font-family: Pretendard;
        font-size: 11px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 13px;
        letter-spacing: normal;
        text-align: left;
        color: #f15928;
    }

    .footer_sitemap_list {
        display: flex;
        gap: 25px;
    }

    .footer_sitemap_list_item_wrap {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .footer_sitemap_list_item {
        display: flex;
        font-family: Pretendard;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
        cursor: pointer;
        width: fit-content;
    }

    .footer_copyright {
        display: flex;
        align-items: center;
        width: 100%;
        height: 36px;
        border-top: 1px solid #1f299c;
        padding-left: 21px;
    }

    .footer_copyright>span {
        height: 12px;
        font-family: Pretendard;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 12px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .floating_btn {
        width: 56px;
        height: 56px;
    }


    .float_btn_area {
        padding: 8px 10px;
        border-radius: 28px;
        background-color: #1f299c;
    }

    .float_btn_area .float_root_btn {
        display: none;
    }

    .float_btn_area :global .ant-float-btn-group-wrap {
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
        gap: 21px;
    }

    .floating_btn_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
    }

    .floating_btn_item:last-child {
        margin-bottom: 7px;
    }

    .floating_btn {
        width: 36px;
        height: 36px;
        background-color: rgba(255, 255, 255, 0.17);
        margin-bottom: 0px !important;
    }

    .floating_btn :global .ant-float-btn-body {
        background-color: rgba(255, 255, 255, 0.17);
    }

    .floating_btn :global .ant-float-btn-content {
        width: 100% !important;
    }

    .floating_btn :global .ant-float-btn-icon {
        margin: 0 !important;
        width: 100% !important;
        display: flex;
        justify-content: center;
    }

    .floating_btn_label {
        height: 13px;
        font-family: Pretendard;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 13px;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    .swipper_area {
        display: flex;
        width: 100%;
        height: 634px;
        justify-content: center;
        position: relative;
        overflow: hidden;
    }

    .swipper_item_background {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: cover;
        transform: scale(1.1) rotate(-0.01deg);
        transition: 0.3s;
        opacity: 1;
        animation: fadeInBg 10s linear both;
    }

    .swipper_item_background:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .swipper_item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    @keyframes fadeInBg {
        0% {
            transform: scale(1.2) rotate(-0.01deg);
        }

        100% {
            transform: scale(1) rotate(-0.01deg);
        }
    }

    .swipper_contents {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 290px;
        gap: 27px;
    }

    .swipper_title_area {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
    }

    .swipper_title {
        font-family: LibreBaskerville;
        font-size: 29px;
        /* height: 43px;
        line-height: 43px; */
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        white-space: pre-line;
    }

    /* .swipper_title:last-child {
        height: 29px;
        line-height: 29px;
        font-size: 24px;
    } */

    .swipper_explanation {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% - 40px);
        padding: 0 20px;
        gap: 37px;
    }

    .swipper_text {
        width: 100%;
        display: flex;
        flex-direction: column;
        /* margin-top: 27px; */
    }

    .swipper_text_item {
        width: 100%;
        display: flex;
        font-family: Pretendard;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.41;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        gap: 10px;
    }

    .swipper_text_item:first-child {
        width: 100%;
        line-height: 1.07;
    }

    .swipper_text_item:last-child {
        width: 100%;
        line-height: 1.25;
    }

    .rotate_item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
    }

    .rotate_img {
        width: 112px;
        height: 112px;
        animation: rotate_image 10s linear infinite;
        transform-origin: 50% 50%;
    }

    .arrow_img {
        position: absolute;
        width: 29.3px !important;
        height: 16.7px !important;
    }

    @keyframes rotate_image {
        100% {
            transform: rotate(360deg);
        }
    }

    /* .counting_area_wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: center;
        width: calc(100% - 40px);
        height: 335px;
        padding: 41px 20px;
        background-color: #F2F6FF;
        gap: 9px;
    } */

    .counting_area_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100% - 40px);
        /* height: 353px; */
        padding: 41px 20px 20px;
        background-color: #F2F6FF;
        justify-content: center;
        /* align-items: flex-end; */
        gap: 20px;
    }

    .counting_area {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: center;
        width: calc(100% - 40px);
        height: 335px;
        gap: 9px;
    }

    .text_description {
        width: calc(100% - 20px);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 10px;
        padding-right: 20px;
    }

    .count_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        gap: 23px;
        background-color: #ffffff;
    }

    .count_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }

    .count_value {
        font-family: LibreBaskerville;
        font-size: 27px;
        /* height: 33px;
        line-height: 33px; */
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #1f299c;
    }

    .count_title {
        font-family: Pretendard;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        /* height: 18px;
        line-height: 18px; */
        letter-spacing: normal;
        text-align: center;
        color: #1f299c;
    }

    .count_text {
        font-family: Pretendard;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        height: 32px;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: center;
        color: #5f66b2;
        white-space: pre-line;
    }

    .webinar_area {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100% - 40px);
        /* height: 995px; */
        border-bottom: 1px solid #1f299c;
        padding: 37px 20px 42.5px;
        gap: 25px;
    }

    .webinar_title {
        font-family: LibreBaskerville;
        font-size: 28px;
        height: 35px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 35px;
        letter-spacing: normal;
        color: #1f299c;
    }

    .webinar_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        height: 100%;
        gap: 25px;
    }

    .webinar_register_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* border-right: 1px solid #1f299c; */
        width: 100%;
        height: 100%;
        /* padding-right: 62px; */
    }

    .webinar_register_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .webinar_register_item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
    }

    .webinar_register_label {
        font-family: Pretendard;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #f15928;
        height: 19px;
        line-height: 19px;
        margin-bottom: 14px;
    }

    .webinar_register_title {
        font-family: LibreBaskerville;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
        height: 24px;
        line-height: 24px;
        margin-bottom: 9px;
    }

    .webinar_register_title>span {
        font-weight: bold;
    }

    .webinar_register_text {
        font-family: Pretendard;
        font-size: 11px;
        height: 13px;
        line-height: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
        margin-bottom: 14px;
    }

    .webinar_register_img {
        width: 100%;
    }

    .webinar_view_more_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 37px;
        border: solid 1px #a5a5a5;
        background-color: #ffffff;
        font-family: Pretendard;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #222;
        cursor: pointer;
        margin-top: 9.6px;
        transition: all 0.5s;
    }

    .webinar_view_more_btn:hover {
        background-color: #1f299c;
        color: #ffffff;
    }

    .webinar_library_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .webinar_library_label {
        font-family: Pretendard;
        font-size: 16.5px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #f15928;
        height: 20px;
        line-height: 20px;
        margin-bottom: 3px;
    }

    .webinar_library_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .webinar_library_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 138px;
        border-bottom: 1px solid #dfdfdf;
        padding-top: 11px;
        padding-bottom: 11px;
    }

    .webinar_library_item:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    .webinar_library_img_skeleton {
        width: 138px;
        height: 138px;
    }

    .webinar_library_info_wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 13px;
        height: 100%;
    }

    .webinar_library_info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: calc(100% - 17px);
        padding: 9px 0 8px;
    }

    .webinar_library_info_text {
        display: flex;
        flex-direction: column;
        gap: 2px;
    }

    .webinar_library_index {
        font-family: Pretendard;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        height: 19px;
        line-height: 19px;
        letter-spacing: normal;
        color: #1f299c;
    }

    .webinar_library_title {
        font-family: Pretendard;
        font-size: 14.5px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        height: 17px;
        line-height: 17px;
        letter-spacing: normal;
        color: #1f299c;
    }

    .webinar_library_datetime {
        font-family: Pretendard;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        height: 13px;
        line-height: 13px;
        letter-spacing: normal;
        color: #1f299c;
    }

    .webinar_library_btn_wrap {
        display: flex;
        align-items: flex-end;
        height: 100%;
    }

    .webinar_library_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border: solid 1px #c0c0c0;
        border-radius: 50%;
        cursor: pointer;
    }

    .webinar_library_btn>img {
        width: 12.5px;
        height: 11.8px;

    }

    .about_jam_area {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .about_jam_preview {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: calc(100% - 40px);
        /* height: 658.5px; */
        padding: 35.5px 20px 99.7px;
        gap: 37px;
    }

    .about_jam_preview_text_wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
    }

    .about_jam_preview_label_wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10.5px;
        margin-bottom: 21px;
    }

    .about_jam_preview_border {
        width: 36px;
        height: 1px;
        background-color: #1f299c;
    }

    .about_jam_preview_label {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        height: 15px;
        line-height: 15px;
        letter-spacing: normal;
        color: #1f299c;
    }

    .about_jam_preview_title {
        font-family: LibreBaskerville;
        font-size: 31px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        height: 38px;
        line-height: 38px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;

    }

    .about_jam_preview_title:last-child {
        font-size: 22px;
        height: 27px;
        line-height: 27px;
        margin-left: 44px;
    }

    .about_jam_preview_text_item {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .about_jam_preview_text {
        margin-top: 31px;
        font-family: Pretendard;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
        /* height: 43px; */
    }

    .about_jam_preview_text>div:first-child {
        text-align: left;
    }

    .about_jam_preview_text>div:last-child {
        text-align: right;
    }

    .about_jam_preview_text>div>span {
        font-weight: 600;
    }

    .about_jam_preview_img_wrap {
        display: flex;
        align-items: flex-end;
        width: 100%;
        position: relative;
    }

    .about_jam_preview_img {
        width: 100%;
    }

    .about_jam_preview_img_wrap .rotate_item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        right: 36px;
        bottom: -80.5px;
        z-index: 2;
    }

    .about_jam_description {
        display: flex;
        flex-direction: column;
        /* align-items: center;
        justify-content: center; */
        width: calc(100% - 40px);
        padding: 36px 20px 60px;
        background: #19249D;
        gap: 31px;
    }

    .about_jam_description_img_wrap {
        display: flex;
        align-items: flex-end;
        max-width: 720px;
    }

    .about_jam_description_img {
        width: 100%;
        margin-top: 14px;
    }

    .about_jam_description_wrap {
        display: flex;
        flex-direction: column;
        gap: 86px;
    }

    .about_jam_description_content {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .about_jam_description_item {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }

    .about_jam_description_summary_content {
        padding-left: 23px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .about_jam_description_summary_item {
        display: flex;
        gap: 12px;
    }

    .about_jam_description_summary_label {
        width: 13px;
        height: 13px;
        background-color: #f15928;
        border-radius: 50%;
    }

    .about_jam_description_summary_text_wrap {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 18px;
    }

    .about_jam_description_summary_text {
        font-family: LibreBaskerville;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        white-space: pre-line;
    }

    .products_area {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: calc(100% - 40px);
        /* height: 713px; */
        background-color: #F5F5F5;
        padding: 31px 20px 41px;
        gap: 27px;
    }

    .products_title_wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 12px;
    }

    .products_title {
        font-family: LibreBaskerville;
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        height: 35px;
        line-height: 35px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .products_explanation {
        padding-left: 23px;
        padding-right: 39.5px;
        display: flex;
        align-items: flex-start;
        gap: 10.5px;
    }

    .products_explanation>div {
        width: 36px;
        height: 1px;
        background-color: #1f299c;
        margin-top: 7.5px;
    }

    .products_explanation>span {
        font-family: Pretendard;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.46;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .product_swiper_area {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        gap: 25px;
    }

    .product_swiper_prev_btn,
    .product_swiper_next_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border: solid 1px #c0c0c0;
        border-radius: 50%;
        cursor: pointer;
    }

    .product_swiper_prev_btn>img,
    .product_swiper_next_btn>img {
        width: 12.5px;
        height: 11.8px;
    }

    .product_swiper_prev_btn>img {
        transform: rotate(180deg);
    }

    .product_swiper_item_img_wrapper {
        display: flex;
        width: 100%;
        height: calc(100% - 93px);
        justify-content: center;
    }


    .product_swiper_item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 13px;
        cursor: pointer;
    }

    .product_swiper_item_text {
        display: flex;
        flex-direction: column;
        gap: 7px;
        align-items: center;
        justify-content: center;
    }

    .product_swiper_slide {
        width: 100% !important;
        height: 100%;
    }

    .product_swiper_name {
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        height: 24px;
        line-height: 24px;
        letter-spacing: normal;
        text-align: center;
        color: #1f299c;
    }

    .product_swiper_explain {
        height: 14px;
        font-family: Pretendard;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 14px;
        letter-spacing: normal;
        text-align: center;
        color: #1f299c;
    }

    .newsletter_area {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: calc(100% - 40px);
        /* height: 381px; */
        padding: 36px 20px;
        gap: 22px;
    }

    .newsletter_title_wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        gap: 12px;
    }

    .newsletter_title {
        font-family: LibreBaskerville;
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        height: 35px;
        line-height: 35px;
        letter-spacing: normal;
        text-align: left;
        color: #f15928;
    }

    .newsletter_explanation {
        padding-left: 23px;
        padding-right: 65px;
        display: flex;
        /* align-items: center; */
        gap: 10.5px;
    }

    .newsletter_explanation>div {
        width: 36px;
        height: 1px;
        background-color: #f15928;
        margin-top: 7.5px;
    }

    .newsletter_explanation>span {
        font-family: Pretendard;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.46;
        letter-spacing: normal;
        text-align: left;
        color: #f15928;
    }

    .newsletter_viewall {
        margin-top: 20px;
        display: flex;
        align-items: center;
        gap: 6px;
        justify-content: flex-end;
    }

    .newsletter_viewall>span {
        height: 14px;
        font-family: Pretendard;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 14px;
        letter-spacing: normal;
        text-align: left;
        color: #f15928;
    }

    .newsletter_viewall_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border: solid 1px #f15928;
        /* background-color: #c0c0c0; */
        border-radius: 50%;
    }

    .newletter_list {
        display: flex;
        align-items: flex-start;
        width: 100%;
        gap: 9px;
    }

    .newletter_item {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 9px;
    }

    .newsletter_item_title {
        height: 18px;
        font-family: Pretendard;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 18px;
        letter-spacing: normal;
        text-align: left;
        color: #f15928;
    }

    .newsletter_item_text {
        font-family: Pretendard;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.46;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .newsletter_list_wrapper {
        display: flex;
        gap: 48px;
    }

    .newsletter_item {
        width: 300px;
        height: 300px;
        background-color: #808080;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        overflow: hidden;
    }

    /* 움직이는 텍스트 */
    .slideText_area {
        height: 63px;
        background-color: #f15928;
        position: relative;
        width: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .slideText_swiper {
        width: 100%;
        height: 100%;
    }

    .slideText_swiper .swiper_wrapper {
        gap: 10px;
    }

    .slide_list {
        position: absolute;
        white-space: nowrap;
        will-change: transform;
        animation: marquee 60s linear infinite;
        /* width: 100%; */
        height: 100%;
    }

    @keyframes marquee {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(-50%);
        }
    }

    .slide_list_content {
        display: flex !important;
        height: 100%;
        align-items: center;
        gap: 20px;
    }

    .slide_text {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        position: relative;
    }

    .slide_middot {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #fff;
    }

    .floating_btn {
        width: 53px;
        height: 53px;
    }


    .float_btn_area {
        padding: 9px 10px;
        border-radius: 27px;
        background-color: #1f299c;
    }

    .float_btn_area .float_root_btn {
        display: none;
    }

    .float_btn_area :global .ant-float-btn-group-wrap {
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
        gap: 19px;
    }

    .floating_btn_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3px;
    }

    .floating_btn {
        width: 32px;
        height: 32px;
        background-color: rgba(255, 255, 255, 0.17);
        margin-bottom: 0px !important;
    }

    .floating_btn :global .ant-float-btn-body {
        background-color: rgba(255, 255, 255, 0.17);
    }

    .floating_btn :global .ant-float-btn-content {
        width: 100% !important;
    }

    .floating_btn :global .ant-float-btn-icon {
        margin: 0 !important;
        width: 100% !important;
        display: flex;
        justify-content: center;
    }

    .floating_btn_label {
        height: 12px;
        font-family: Pretendard;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 12px;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }
}