.mypage {
  display: grid;
  height: calc(100vh - 80px);
}

.info {
  background-color: #f2f5fe;
}

.info_comment {
  font-family: Pretendard;
  color: #1f299c;
  overflow: hidden;
  /* white-space: nowrap; */
  /* word-break: keep-all; */
}

.menu {
  display: flex;
}

.menu_item {
  cursor: pointer;
  display: flex;
  transition-duration: 100ms;
  transition-property: color, stroke;
  transition-timing-function: linear;
  color: #1f299c;
  stroke: #1f299c;
}

.menu_item.active,
.menu_item:hover {
  stroke: #f15928;
  color: #f15928;
}

/* .underline {
  height: 1px;
  width: 100%;
  background-color: black;
} */

/* PC (해상도 1024px)*/
@media all and (min-width: 1024px) {
  .mypage {
    grid-template-columns: 474px 1fr;
  }

  .info {
    height: 100%;
    width: 100%;
    padding-left: 63px;
    /* padding-left: 8%; */
  }

  .info_comment {
    margin: 168px 0 97px;
    font-size: 50px;
    /* border: 1px solid black; */
  }

  .menu {
    row-gap: 30px;
    flex-direction: column;
  }

  .menu_item {
    font-size: 21px;
    column-gap: 15px;
    stroke-width: 1.7px;
  }

  .menu_item>svg {
    width: 22px;
    height: 22px;
    display: flex;
  }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023px) {
  .mypage {
    grid-template-columns: 213px 1fr;
  }

  .info {
    height: 100%;
    width: calc(100% - 32px);
    padding-left: 32px;
  }

  .menu {
    flex-direction: column;
    row-gap: 11px;
  }

  .menu_item {
    column-gap: 5px;
    stroke-width: 1.5px;
  }

  .menu_item>svg,
  .menu_item>svg>path {
    width: 17px;
    height: 17px;
  }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  .mypage {
    grid-template-columns: 1fr;
  }

  .info {
    height: 162px;
    width: calc(100% - 36px);
    padding: 0 18px;
  }
}

/** 공통 **/
/* PC (해상도 1024px)*/
@media all and (min-width: 1024px) {
  .content {
    display: grid;
    grid-template-columns: 480px 1px 1fr 1px 1fr;
    /* grid-template-rows: 1fr 1px auto 1px auto; */
    grid-template-rows: auto 1px auto 1px auto;
  }

  .cotent_title {
    font-size: 25px;
  }

  .content_item:nth-child(1) {
    padding: 35px 33px 64px 141px;
    width: calc(100% - 176px);
    height: calc(100% - 99px);
  }

  .content_item:nth-child(3) {
    padding: 35px 0 30px 33px;
    width: calc(100% - 33px);
    height: calc(100% - 65px);
  }

  .content_item:nth-child(4),
  .content_item:nth-child(6) {
    grid-column: 1 / sapn 3;
  }

  .content_item:nth-child(5) {
    padding: 34px 0 34px 140px;
    width: calc(100% - 140px);
    height: calc(100% - 68px);
    grid-column: 1 / sapn 3;
  }

  .content_item:nth-child(7) {
    padding: 34px 0 67px 140px;
    width: calc(100% - 140px);
    height: calc(100% - 101px);
    grid-column: 1 / sapn 3;
  }

  .content_line {
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    background-color: #bdbfd9;
  }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023px) {
  .content {
    display: grid;
    grid-template-columns: 288px 1px 1fr 1px 1fr;
    /* grid-template-rows: 1fr 1px 1fr 1px 1fr; */
    grid-template-rows: auto 1px auto 1px auto;
  }

  .cotent_title {
    font-size: 22px;
  }

  .content_item:nth-child(1) {
    padding: 39.5px 20.5px 67.5px 60.5px;
    width: calc(100% - 81px);
    height: calc(100% - 107px);
  }

  .content_item:nth-child(3) {
    padding: 39.5px 0 29.5px 19.5px;
    width: calc(100% - 19.5px);
    height: calc(100% - 69px);
  }

  .content_item:nth-child(4),
  .content_item:nth-child(6) {
    grid-column: 1 / sapn 3;
  }

  .content_item:nth-child(5) {
    padding: 39.5px 0 39.5px 59.5px;
    width: calc(100% - 59.5px);
    height: calc(100% - 79px);
    grid-column: 1 / sapn 3;
  }

  .content_item:nth-child(7) {
    padding: 39.5px 0 58px 59.5px;
    width: calc(100% - 59.5px);
    height: calc(100% - 97.5px);
    grid-column: 1 / sapn 3;
  }

  .content_line {
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    background-color: #bdbfd9;
  }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
  }

  .cotent_title {
    font-size: 22px;
  }

  .content_item {
    padding: 0 100%;
  }

  .content_item:nth-child(2) {
    margin-top: 34px;
    padding-bottom: 34px;
    min-width: 335px;
    border-bottom: 1px solid #bdbfd9;
  }

  .content_item:nth-child(3) {
    margin-top: 32px;
    padding-bottom: 32px;
    min-width: 335px;
    border-bottom: 1px solid #bdbfd9;
  }

  .content_item:nth-child(4) {
    margin-top: 32px;
    padding-bottom: 32px;
    min-width: 335px;
  }
}

.content_item_container {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: max-content;
}

.skeleton {
  width: 100% !important;
  height: 100% !important;
}

.content {
  width: 100%;
  height: 100%;
}

.cotent_title {
  font-family: LibreBaskerville;
  color: #1f299c;
  font-weight: bold;
}

.content_item {
  width: 100%;
  max-width: max-content;
  position: relative;
}

.like_btn {
  z-index: 2;
  position: absolute;
  width: 34px;
  height: 34px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #a8abc0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  transition-duration: 150ms;
  transition-property: border-color, background-color;
  transition-timing-function: linear;
}

.like_btn.top {
  top: 10px;
}

.like_btn.bottom {
  bottom: 10px;
}

.like_btn:hover {
  border-color: #1f299c;
}

.like_btn:active {
  background-color: #a8abc0;
}

/* Newsletter */
@media all and (min-width: 1024px) {
  .newsletter {
    margin-top: 25px;
    width: 1164px;
    /* max-width: 1164px; */
    max-height: 268px;
    /* width: 100%; */
  }

  .library_swiper {
    margin-top: 24px;
    width: 1164px;
    max-height: 400px;
  }

  .newsletter_item {
    grid-template-rows: 195.5px 1fr;
    row-gap: 16.5px;
  }

  .library_title,
  .newsletter_title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.88;
  }

  .library_comment,
  .newsletter_comment {
    font-size: 11.5px;
    line-height: 1.3;
  }

  .slide_btn_area {
    top: -5px;
    column-gap: 10px;
  }

  .slide_btn {
    width: 26px;
    height: 26px;
  }

  .slide_btn>img {
    width: 14px;
    height: 14px;
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .newsletter {
    margin-top: 17px;
    max-width: 687px;
    /* width: 100%; */
    width: 688px;
  }

  .library_swiper {
    margin-top: 17px;
    max-width: 687px;
    width: 688px;
  }

  .newsletter_item {
    grid-template-rows: 151px 1fr;
    row-gap: 10px;
  }

  .library_title,
  .newsletter_title {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.75;
  }

  .library_comment,
  .newsletter_comment {
    font-size: 9px;
    line-height: 1.33;
  }

  .slide_btn_area {
    top: 0px;
    column-gap: 12px;
  }

  .slide_btn {
    width: 26px;
    height: 26px;
  }

  .slide_btn>img {
    width: 14px;
    height: 14px;
  }
}

@media all and (max-width: 767px) {
  .newsletter {
    margin-top: 27px;
    /* max-width: 335px; */
    width: 335px;
  }

  .library_swiper {
    margin-top: 27px;
    width: 335px;
  }

  .newsletter_item {
    grid-template-rows: 114px 1fr;
    row-gap: 9px;
  }

  .library_title,
  .newsletter_title {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.27;
  }

  .library_comment,
  .newsletter_comment {
    font-size: 13px;
    line-height: 1.46;
  }

  .slide_btn_area {
    top: -5px;
    column-gap: 5px;
  }

  .slide_btn {
    width: 30px;
    height: 30px;
  }

  .slide_btn>img {
    width: 16px;
    height: 16px;
  }
}

.newsletter {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.library_swiper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.library_swiper_item,
.newsletter_item {
  cursor: pointer;
  display: grid;
}

.newsletter_img {
  position: relative;
  width: 100% !important;
  height: 100% !important;
}

.newsletter_description {
  text-align: left;
  font-family: Pretendard;
}

.newsletter_title {
  color: #f15928;
}

.newsletter_comment {
  color: #1f299c;
}

.slide_btn_area {
  position: absolute;
  right: 0;
  display: flex;
}

.slide_btn {
  cursor: pointer;
  border: solid 1px #c0c0c0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide_btn:nth-of-type(1) {
  transform: rotate(180deg);
}

/** register **/
/* PC (해상도 1024px)*/
@media all and (min-width: 1024px) {
  .register_container {
    margin-top: 23px;
    /* height: calc(100% - 23px); */
    /* max-height: 304px; */
    /* width: 100%; */
    width: 303px;
  }

  .register_item {
    grid-template-rows: 170px 1fr;
  }

  .register_item_info {
    padding: 19px 16px 13px 16px;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
  }

  .register_item_info {
    row-gap: 10px;
  }

  .register_item_title {
    font-size: 20px;
  }

  .register_item_description {
    margin-top: 12px;
    font-size: 13px;
  }

  .register_item_time {
    column-gap: 5px;
    font-size: 13.5px;
  }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023px) {
  .register_container {
    margin-top: 16px;
    /* height: calc(100% - 16px); */
    /* max-height: 207px; */
    /* width: 100%; */
    width: 206px;
  }

  .register_item {
    grid-template-rows: 114px 1fr;
  }

  .register_item_info {
    padding: 8px 10px 10px 10px;
    width: calc(100% - 20px);
    height: calc(100% - 18px);
  }

  .register_item_info {
    row-gap: 12px;
  }

  .register_item_title {
    font-size: 15px;
    font-weight: bold;
  }

  .register_item_description {
    margin-top: 5px;
    font-size: 9px;
  }

  .register_item_time {
    column-gap: 2px;
    font-size: 10px;
  }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  .register_container {
    margin-top: 27px;
    /* height: calc(100% - 27px); */
    /* max-width: 333px; */
    /* max-height: 379px; */
    width: 335px;
  }

  .register_item {
    grid-template-rows: 180px 1fr;
  }

  .register_item_info {
    padding: 25px 20px 33px 20px;
    width: calc(100% - 40px);
    height: calc(100% - 58px);
  }

  .register_item_info {
    row-gap: 33px;
  }

  .register_item_title {
    font-size: 25px;
    font-weight: bold;
  }

  .register_item_description {
    margin-top: 12px;
    font-size: 15px;
  }

  .register_item_time {
    column-gap: 2px;
    font-size: 16px;
  }
}

.register_container {
  position: relative;
}

.register_item {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #bebfcc;
  background-color: #fff;
  cursor: pointer;
  transition-duration: 150ms;
  transition-property: border-color;
  transition-timing-function: linear;
  display: grid;
}

.register_item:hover {
  border-color: #1f299c;
}

.register_item_img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100% !important;
  height: 100% !important;
}

.register_item_info {
  font-family: Butler;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.register_item_title {
  color: #111;
}

.register_item_description {
  color: #757575;
}

.register_item_time {
  display: flex;
  align-items: center;
  color: #282828;
}

/** library **/
/* PC (해상도 1024px)*/
@media all and (min-width: 1024px) {
  .library_item {
    max-width: 389px;
    max-height: 125px;
  }

  .library_item_container {
    padding: 13px 12px;
    width: calc(100% - 24px);
    height: calc(100% - 26px);
    grid-template-columns: 100px 1fr;
    column-gap: 14px;
  }

  .library_container {
    max-height: 339px;
    width: max-content;
  }

  .library_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* grid-template-rows: 1fr 1fr; */
    grid-auto-rows: auto;
    column-gap: 14px;
    row-gap: 14px;
    width: 100%;
    height: 100%;
  }

  .library_paging {
    padding-top: 50px;
    height: 33px;
  }

  .library_item_title {
    font-size: 19px;
    font-weight: 600;
  }

  .library_item_description {
    margin-top: 11px;
    font-size: 15px;
  }

  .library_item_time {
    grid-template-columns: auto auto auto auto auto;
    column-gap: 4px;
    font-size: 11px;
  }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023px) {
  .library_item {
    max-width: 215px;
    max-height: 90px;
  }

  .library_container {
    max-height: 345px;
    width: max-content;
  }

  .library_item_container {
    padding: 8px;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    grid-template-columns: 76px 1fr;
    column-gap: 8px;
  }

  .library_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* grid-template-rows: 1fr 1fr; */
    grid-auto-rows: auto;
    column-gap: 14px;
    row-gap: 14px;
    width: 100%;
    height: 100%;
  }

  .library_paging {
    /* padding-top: 50px; */
    padding-top: 10px;
    height: 33px;
  }

  .library_item_title {
    font-size: 16px;
    font-weight: 600;
  }

  .library_item_time {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: auto auto auto auto;
    column-gap: 4px;
    font-size: 11px;
  }

  .library_item_time>p:nth-of-type(1) {
    grid-column: 1 / sapn 4;
  }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  .library_item {
    max-width: 335px;
    max-height: 108px;
  }

  .library_item_container {
    padding: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    grid-template-columns: 89px 1fr;
    column-gap: 10px;
  }

  .library_content {
    display: grid;
    grid-template-columns: 1fr;
    /* grid-template-rows: 1fr 1fr 1fr 1fr; */
    grid-auto-columns: auto;
    row-gap: 15px;
    /* width: 100%; */
    width: 335px;
    height: 100%;
  }

  .library_paging {
    padding-top: 29px;
    height: 36px;
  }

  .library_item_title {
    font-size: 17px;
    font-weight: 600;
  }

  .library_item_description {
    margin-top: 2px;
    font-size: 3;
  }

  .library_item_time {
    grid-template-columns: auto auto auto auto auto;
    column-gap: 4px;
    font-size: 10px;
  }
}

.library_container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 23px 0 30px;
  height: calc(100% - 53px);
  min-width: 335px;
}

.library_item {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #bebfcc;
  background-color: #fff;
  cursor: pointer;
  transition-duration: 150ms;
  transition-property: border-color;
  transition-timing-function: linear;
}

.library_item:hover {
  border-color: #1f299c;
}

.library_item_container {
  position: relative;
  display: grid;
}

.library_item_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-family: Pretendard;
}

.library_item_title {
  color: #1f299c;
}

.library_item_description {
  color: #1f299c;
}

.library_item_time {
  display: grid;
  align-items: center;
  color: #1f299c;
}

.library_item_img {
  width: calc(100% - 2px) !important;
  height: calc(100% - 1px) !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #bebfcc;
  border-right: 1px solid #bebfcc;
  border-top: 1px solid #bebfcc;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.library_item_img_article {
  position: absolute;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

/* PC (해상도 1024px)*/
@media all and (min-width: 1024px) {
  .mypage {
    grid-template-columns: 474px 1fr;
  }

  .info {
    height: 100%;
    width: calc(100% - 63px);
    padding-left: 63px;
  }

  .info_comment {
    margin: 168px 0 97px;
    font-size: 50px;
    width: fit-content;
  }

  .info_comment>p {
    padding-bottom: 5px;
  }

  .menu {
    row-gap: 30px;
    flex-direction: column;
  }

  .menu_item {
    font-size: 21px;
    column-gap: 15px;
    stroke-width: 1.7px;
  }

  .menu_item>svg {
    width: 22px;
    height: 22px;
    display: flex;
  }

  /* .content {
    width: 100%;
    height: 100%;
    grid-template-columns: 480px 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .content_itme:nth-child(1) {
    padding: 35px 33px 35px 140px;
    width: calc(100% - 173px);
    height: calc(100% - 70px);
    border-right: 1px solid #bdbfd9;
  }
  .content_itme:nth-child(2) {
    padding: 35px 140px 35px 33px;
    width: calc(100% - 173px);
    height: calc(100% - 70px);
  }
  .content_itme:nth-child(3) {
    padding: 35px 140px;
    width: calc(100% - 280px);
    height: calc(100% - 70px);
    border-top: 1px solid #bdbfd9;
    grid-column: 1 / sapn 2;
  } */
  .cotent_title {
    font-size: 25px;
    font-weight: bold;
  }

  .library_item_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
  }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023px) {
  .mypage {
    grid-template-columns: 213px 1fr;
    height: calc(100vh - 80px);
  }

  .info {
    height: 100%;
    width: calc(100% - 32px);
    padding-left: 32px;
  }

  .info_comment {
    margin-top: 184.5px;
    font-family: Pretendard;
    font-size: 27px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
  }

  .info_comment>p {
    /* height: 27px; */
    padding-bottom: 5px;
  }

  .menu {
    flex-direction: column;
    row-gap: 13px;
    margin-top: 49px;
  }

  .menu_item {
    column-gap: 5px;
    stroke-width: 1.5px;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.07px;
    text-align: left;
    color: #1f299c;
    gap: 5px;
    white-space: nowrap;
    overflow: hidden;
  }

  .menu_item>svg,
  .menu_item>svg>path {
    width: 17px;
    height: 17px;
  }

  .library_item_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
  }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  .mypage {
    /* grid-template-columns: 1fr; */
    height: calc(100vh - 57px);
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .info {
    height: 162px;
    width: calc(100% - 36px);
    padding: 0 18px;
    border-bottom: 1px solid #1f299c;
  }

  .info_comment {
    margin-top: 39px;
    font-family: Pretendard;
    font-size: 33px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
  }

  .info_comment>p {
    /* height: 31px; */
    padding-bottom: 5px;
  }

  .menu {
    /* justify-content: space-between; */
    gap: 13px;
    margin-top: 40px;
    width: 100%;
    overflow-x: auto;
    margin-bottom: 15px;
  }

  .menu_item {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.07px;
    text-align: left;
    gap: 5px;
    white-space: nowrap;
    overflow: hidden;
  }

  .menu_item>svg,
  .menu_item>svg>path {
    width: 20px;
    height: 20px;
  }

  .library_item_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    row-gap: 6px;
    padding: 8px 2px;
    flex-direction: column;
  }
}

.swiper {
  width: 100% !important;
  height: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.library_btn {
  height: 100%;
  width: 80px;
  background-color: #fff;
  border: 1px solid #1f299c;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.97;
  color: #1f299c;
  cursor: pointer;
  transition: all 0.5s;
}

.library_btn:hover {
  background-color: #1f299c;
  color: #fff;
}

.library_item_footer {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid #bebfcc;
}