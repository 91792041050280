/* PC (해상도 1025px)*/
@media all and (min-width:1024px) {
    .academy_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 1412px;
        padding: 61px 100px 133px;
        margin: 0 auto;
        gap: 42px;
        position: relative;
    }

    .title {
        height: 25px;
        font-family: LibreBaskerville;
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 25px;
        letter-spacing: -0.14px;
        text-align: left;
        color: #1f299c;
    }

    .title>span {
        font-family: Pretendard;
        margin-left: 24px;
        border-left: 1px solid #1f299c;
        padding-left: 24px;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .list_type_tab {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: sticky;
        top: 80px;
        z-index: 10;
        padding-top: 30px;
        background-color: #ffffff;
    }

    .list_type_tab_item,
    .list_type_tab_item_active {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
        height: 80px;
        background-color: #f9f9f9;
        color: #888;
        cursor: pointer;
        border: solid 1px #ddd;
        font-size: 20px;
        font-weight: 600;
        color: #111111;
    }

    .list_type_tab_item:last-child {
        border-left: none;
    }

    .list_type_tab_item:hover,
    .list_type_tab_item_active {
        background-color: #ffffff;
        color: #1f299c;
        height: 79px;
        border-bottom: solid 2px #1f299c;
    }

    .academy_title_wrapper {
        display: flex;
        flex-direction: column;
        gap: 14px;
    }

    .academy_title {
        height: 25px;
        font-size: 29px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 25px;
        letter-spacing: -0.14px;
        text-align: left;
        color: #1f299c;
    }

    .academy_title_explain {
        height: 17px;
        opacity: 0.8;
        font-family: Pretendard;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 17px;
        letter-spacing: -0.08px;
        text-align: left;
        color: #1f299c;
    }

    .academy_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 94px;
        column-gap: 20px;
        width: 100%;
    }

    .academy_item {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 338px;
        position: relative;
        overflow: hidden;
    }

    .academy_item_img_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease;
    }

    .academy_item_img_wrapper:hover {
        transform: scale(1.1);
    }

    .academy_item_img {
        width: 100%;
        height: 100%;
    }

    .play_icon_wrapper {
        width: 90px;
        height: 90px;
        position: absolute;
        /* left: 50%;
        top: 50%; */
        /* transform: translate(-50%, -50%); */
        /* font-size: 90px; */
        /* color: rgba(0, 0, 0, 0.34); */
        background-color: rgba(0, 0, 0, 0.34);
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }

    .play_icon {
        width: 30px;
        height: 30px;
        transition: all 0.5s ease;
    }

    .academy_item_like {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 38px;
        height: 38px;
        border-radius: 5px;
        border: solid 1px #a8abc0;
        background-color: #fff;
        top: 12px;
        right: 18px;
        cursor: pointer;
    }

    .academy_item_like>img {
        width: 18px;
        height: 16px;
    }

    .academy_item_title {
        font-family: Pretendard;
        font-size: 19px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #f15928;
    }

    .academy_item_explain {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1024px)*/
@media all and (min-width:768px) and (max-width:1023px) {
    .academy_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 1022px;
        padding: 22.5px 40px 89.5px;
        margin: 0 auto;
        gap: 30px;
    }

    .title {
        height: 23px;
        font-family: LibreBaskerville;
        font-size: 19px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 23px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .title>span {
        font-family: Pretendard;
        margin-left: 20px;
        border-left: 1px solid #1f299c;
        padding-left: 20px;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .list_type_tab {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: sticky;
        top: 80px;
        z-index: 10;
        padding-top: 30px;
        background-color: #ffffff;
    }

    .list_type_tab_item,
    .list_type_tab_item_active {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
        height: 60px;
        background-color: #f9f9f9;
        color: #888;
        cursor: pointer;
        border: solid 1px #ddd;
        font-size: 20px;
        font-weight: 600;
        color: #111111;
    }

    .list_type_tab_item:last-child {
        border-left: none;
    }

    .list_type_tab_item:hover,
    .list_type_tab_item_active {
        background-color: #ffffff;
        color: #1f299c;
        height: 59px;
        border-bottom: solid 2px #1f299c;
    }

    .academy_title_wrapper {
        display: flex;
        flex-direction: column;
        gap: 1px;
    }

    .academy_title {
        height: 23px;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 23px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .academy_title_explain {
        height: 14px;
        opacity: 0.8;
        font-family: Pretendard;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 14px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .academy_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 67px;
        column-gap: 10px;
        width: 100%;
    }

    .academy_item {
        display: flex;
        flex-direction: column;
        width: 294px;
    }

    .academy_item_img_wrapper {
        position: relative;
        overflow: hidden;
    }

    .academy_item_img {
        width: 100%;
        height: 100%;
        transition: all 0.5s ease;
    }

    .academy_item_img:hover {
        transform: scale(1.1);
    }

    .academy_item_like {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 38px;
        height: 38px;
        border-radius: 5px;
        border: solid 1px #a8abc0;
        background-color: #fff;
        top: 12px;
        right: 18px;
        cursor: pointer;
    }

    .academy_item_like>img {
        width: 18px;
        height: 16px;
    }

    .academy_item_title {
        margin-top: 13px;
        font-family: Pretendard;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.08px;
        text-align: left;
        color: #f15928;

    }

    .academy_item_explain {
        margin-top: 7px;
        font-family: Pretendard;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }
}


/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    .academy_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100% - 40px);
        padding: 43px 20px 63.5px;
        gap: 30px;
    }

    .title {
        font-family: LibreBaskerville;
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 35px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
        display: flex;
        flex-direction: column;
        gap: 9px;
    }

    .title>span {
        font-family: Pretendard;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }


    .list_type_tab {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: sticky;
        top: 58px;
        z-index: 10;
        padding-top: 30px;
        background-color: #ffffff;
    }

    .list_type_tab_item,
    .list_type_tab_item_active {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
        height: 60px;
        background-color: #f9f9f9;
        color: #888;
        cursor: pointer;
        border: solid 1px #ddd;
        font-size: 20px;
        font-weight: 600;
        color: #111111;
    }

    .list_type_tab_item:last-child {
        border-left: none;
    }

    .list_type_tab_item:hover,
    .list_type_tab_item_active {
        background-color: #ffffff;
        color: #1f299c;
        height: 59px;
        border-bottom: solid 2px #1f299c;
    }

    .academy_title_wrapper {
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    .academy_title {
        height: 35px;
        font-size: 29px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 35px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .academy_title_explain {
        opacity: 0.8;
        font-family: Pretendard;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .academy_list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 50px;
        width: 100%;
    }

    .academy_item {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .academy_item_img_wrapper {
        position: relative;
        overflow: hidden;
    }

    .academy_item_img {
        width: 100%;
        height: 100%;
        transition: all 0.5s ease;
    }

    .academy_item_img:hover {
        transform: scale(1.1);
    }

    .play_triangle {
        position: absolute
    }

    .academy_item_like {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 31px;
        height: 31px;
        border-radius: 5px;
        border: solid 1px #a8abc0;
        background-color: #fff;
        top: 9px;
        right: 11px;
        cursor: pointer;
    }

    .academy_item_like>img {
        width: 17px;
        height: 17px;
    }

    .academy_item_title {
        margin-top: 12px;
        font-family: Pretendard;
        font-size: 21px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.11px;
        text-align: left;
        color: #f15928;
    }

    .academy_item_explain {
        margin-top: 7px;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }
}