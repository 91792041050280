.wrap {
  /* overflow-y: auto;
  height: 100%; */
  width: 100%;
  /* height: 100%; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  /* overflow-x: hidden; */
}



/* PC (해상도 1024px)*/
@media all and (min-width: 1025px) {
  .header {
    display: flex;
    position: fixed;
    width: 100%;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    background-color: #fff;
    border-bottom: 1px solid #1f299c;
    top: 0;
  }

  .header_left_area {
    width: 253px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header_center_area {
    width: calc(100% - 531px);
    height: 100%;
    display: flex;
    align-items: center;
    border-left: 1px solid #1f299c;
    border-right: 1px solid #1f299c;
    justify-content: space-evenly;
  }

  .header_center_area .menu {
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1f299c;
    cursor: pointer;
  }

  .header_center_area .menu:hover {
    color: #f15928;
  }

  .header_center_area .menu:after {
    display: block;
    content: "";
    border-bottom: solid 2px #f15928;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  .header_center_area .menu:hover:after {
    transform: scaleX(1);
  }

  .header_right_area {
    width: 278px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header_right_area .menu {
    display: flex;
    align-items: center;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1f299c;
    /* padding: 0 36px; */
    cursor: pointer;
    gap: 6px;
  }

  .header_right_area .menu:first-child {
    border-right: 1px solid #1f299c;
    padding-right: 18px;
  }

  .header_right_area .menu:last-child {
    padding-left: 18px;
  }

  .header_right_area .menu:after {
    display: block;
    content: "";
    border-bottom: solid 1px #1f299c;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  .header_right_area .menu:hover:after {
    transform: scaleX(1);
  }

  .user_menu {
    width: 32px;
    height: 32px;
    background-color: #1f299c;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user_icon {
    color: #ffffff;
  }

  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-top: 80px;
    /* overflow-y: auto; */
  }

  .footer {
    display: flex;
    width: 100%;
    /* height: 370px; */
    border-top: 1px solid #1f299c;
  }

  .footer_logo {
    display: flex;
    justify-content: center;
    width: 252px;
    border-right: 1px solid #1f299c;
    padding-top: 57px;
  }

  .footer_wrap {
    display: flex;
    flex-direction: column;
    width: calc(100% - 252px);
    height: 100%;
  }

  .footer_content {
    display: flex;
    width: 100%;
    height: calc(100% - 67px);
  }

  .footer_contactus {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #1f299c;
    padding: 69px 26px 0 33px;
    gap: 21px;
  }

  .footer_contactus_text {
    font-family: Pretendard;
    font-size: 27px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
  }

  .footer_contactus_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 57px;
    background-color: #1f299c;
    font-family: Pretendard;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }

  .footer_contactus_btn>span {
    font-weight: normal;
  }

  .footer_sitemap {
    display: flex;
    padding-top: 70px;
    padding-bottom: 65px;
    padding-left: 60px;
    width: 100%;
    gap: 30px;
    justify-content: space-evenly;
  }

  .footer_sitemap_menu {
    display: flex;
    flex-direction: column;
    gap: 55px;
  }

  .footer_sitemap_title {
    height: 12px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 12px;
    letter-spacing: normal;
    text-align: left;
    color: #f15928;
  }

  .footer_sitemap_list {
    display: flex;
    gap: 40px;
  }

  .footer_sitemap_list_item_wrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .footer_family_list_item_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }

  .footer_sitemap_list_item {
    display: flex;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
    cursor: pointer;
    width: fit-content;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .footer_sitemap_list_item_icon_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 51px;
    height: 51px;
    border-radius: 50%;
    background-color: #1f299c;
  }

  .footer_copyright {
    display: flex;
    align-items: center;
    height: 67px;
    border-top: 1px solid #1f299c;
    padding-left: 34px;
  }

  .footer_copyright>span {
    height: 16px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
  }
}

/* PC (해상도 1920px)*/
@media all and (min-width:1920px) {
  .footer_sitemap {
    display: flex;
    padding-top: 70px;
    padding-left: 60px;
    gap: 100px;
  }

  .footer_contactus_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 523px;
    height: 57px;
    background-color: #1f299c;
    font-family: Pretendard;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }

  .footer_sitemap {
    display: flex;
    padding-top: 70px;
    padding-left: 60px;
    gap: 138px;
    justify-content: flex-start;
  }

  .footer_sitemap_list {
    display: flex;
    gap: 69px;
  }

}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1024px)*/
@media all and (min-width: 768px) and (max-width: 1024px) {
  .header {
    display: flex;
    position: fixed;
    width: 100%;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    background-color: #fff;
    border-bottom: 1px solid #1f299c;
  }

  .header_left_area {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header_center_area {
    width: calc(100% - 310px);
    height: 100%;
    display: flex;
    align-items: center;
    border-left: 1px solid #1f299c;
    border-right: 1px solid #1f299c;
    justify-content: space-evenly;
  }

  .header_center_area .menu {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
    cursor: pointer;
  }

  .header_center_area .menu:hover {
    color: #f15928;
  }

  .header_center_area .menu:after {
    display: block;
    content: "";
    border-bottom: solid 2px #f15928;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  .header_center_area .menu:hover:after {
    transform: scaleX(1);
  }

  .header_right_area {
    width: 190px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header_right_area .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1f299c;
    padding: 0 8px;
    cursor: pointer;
  }

  .header_right_area .menu:first-child {
    border-right: 1px solid #1f299c;
  }

  .header_right_area .menu:after {
    display: block;
    content: "";
    border-bottom: solid 1px #1f299c;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  .header_right_area .menu:hover:after {
    transform: scaleX(1);
  }

  .user_menu {
    width: 26px;
    height: 26px;
    background-color: #1f299c;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user_icon {
    color: #ffffff;
  }

  .user_icon>svg {
    width: 16px;
    height: 16px;
  }

  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-top: 80px;
    /* overflow-y: auto; */
  }

  .footer {
    display: flex;
    width: 100%;
    border-top: 1px solid #1f299c;
    /* height: 370px */
  }

  .footer_logo {
    display: flex;
    justify-content: center;
    width: 165px;
    border-right: 1px solid #1f299c;
    padding-top: 29px;
  }

  .footer_wrap {
    display: flex;
    flex-direction: column;
    width: calc(100% - 166px);
    height: 100%;
  }

  .footer_content {
    display: flex;
    width: 100%;
    height: calc(100% - 67px);
  }

  .footer_contactus {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #1f299c;
    padding: 29px 21px 0px;
    gap: 12.5px;
  }

  .footer_contactus_text {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #1f299c;
  }

  .footer_contactus_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 43px;
    background-color: #1f299c;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }

  .footer_contactus_btn>span {
    font-weight: normal;
  }

  .footer_sitemap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 30px 35px 67px;
    gap: 20px;
  }

  .footer_sitemap_menu {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }

  .footer_sitemap_title {
    height: 13px;
    font-family: Pretendard;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 13px;
    letter-spacing: normal;
    text-align: left;
    color: #f15928;
  }

  .footer_sitemap_list {
    display: flex;
    gap: 25px;
  }

  .footer_sitemap_list_item_wrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .footer_family_list_item_wrap {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
  }

  .footer_sitemap_list_item {
    display: flex;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
    cursor: pointer;
    width: fit-content;
    flex-direction: column;
    align-items: center;
    gap: 8px;

  }

  .footer_sitemap_list_item_icon_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #1f299c;
  }

  .footer_copyright {
    display: flex;
    align-items: center;
    width: calc(100% - 21px);
    height: 36px;
    border-top: 1px solid #1f299c;
    padding-left: 21px;
  }

  .footer_copyright>span {
    height: 12px;
    font-family: Pretendard;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 12px;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
  }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  .header {
    display: flex;
    position: fixed;
    width: 100%;
    height: 57px;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    background-color: #fff;
    border-bottom: 1px solid #1f299c;
    top: 0;
  }

  .header_left_area {
    width: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu_icon {
    width: 18px;
    height: 18px;
    color: #1f299c;
  }

  .menu_icon>svg {
    width: 18px;
    height: 18px;
  }

  .header_center_area {
    width: calc(100% - 126px);
    height: 100%;
    display: flex;
    align-items: center;
    border-left: 1px solid #1f299c;
    border-right: 1px solid #1f299c;
    justify-content: center;
  }

  .header_right_area {
    width: 70px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user_menu {
    width: 32px;
    height: 32px;
    background-color: #1f299c;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user_icon {
    color: #ffffff;
  }

  .user_icon>svg {
    width: 18px;
    height: 18px;
  }

  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-top: 57px;
    /* overflow-y: auto; */
  }

  .footer {
    display: flex;
    width: calc(100% - 40px);
    padding: 46.5px 20px 27.4px;
    border-top: 1px solid #1f299c;
    /* height: 370px; */
  }

  .footer_logo {
    display: flex;
    justify-content: center;
    width: 252px;
    padding-top: 28.1px;
  }

  .footer_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 18.4px;
  }

  .footer_content {
    display: flex;
    align-items: center;
    width: 100%;
    /* height: calc(100% - 67px); */
  }

  .footer_contactus {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12.5px;
  }

  .footer_contactus_text {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    height: 21px;
    line-height: 21px;
    letter-spacing: normal;
    text-align: center;
    color: #1f299c;
  }

  .footer_contactus_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 43px;
    background-color: #1f299c;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  .footer_contactus_btn>span {
    font-weight: 500;
  }

  .footer_sitemap {
    display: flex;
    padding-top: 70px;
    padding-left: 60px;
    gap: 138px;
  }

  .footer_sitemap_menu {
    display: flex;
    flex-direction: column;
    gap: 55px;
  }

  .footer_sitemap_title {
    height: 12px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 12px;
    letter-spacing: normal;
    text-align: left;
    color: #f15928;
  }

  .footer_sitemap_list {
    display: flex;
    gap: 69px;
  }

  .footer_sitemap_list_item_wrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .footer_family_list_item_wrap {
    display: flex;
    gap: 15px;
  }

  .footer_sitemap_list_item {
    display: flex;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
    cursor: pointer;
    width: fit-content;
  }

  .footer_copyright {
    display: flex;
    /* border-top: 1px solid #1f299c; */
    /* padding-left: 34px; */
  }

  .footer_copyright>span {
    font-family: Pretendard;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #1f299c;
    white-space: pre-line;
  }
}