@media all and (min-width: 1024px) {
  .wCard_wrapper {
    width: 445px;
    /* min-width: 445px; */
    height: 257px;
    border: 1px solid grey;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
  .wCard_img {
    height: 100%;
    width: 100%;
    transition: all 0.5s ease;
  }
  .img_hover {
    transform: scale(1.1);
  }
  .wCard_cover {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.17);
  }
  .wCard_play {
    width: 90px;
    height: 90px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* font-size: 90px; */
    /* color: rgba(0, 0, 0, 0.34); */
    background-color: rgba(0, 0, 0, 0.34);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .play_triangle {
    width: 29px;
    height: 33px;
    transition: all 0.5s ease;
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .wCard_wrapper {
    min-width: 270px;
    max-width: 290px;
    height: 164px;
    /* border: 1px solid grey; */
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
  .wCard_img {
    height: 100%;
    width: 100%;
    transition: all 0.5s ease;
  }
  .img_hover {
    transform: scale(1.1);
  }
  .wCard_cover {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.17);
  }
  .wCard_play {
    width: 57px;
    height: 57px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* font-size: 90px; */
    /* color: rgba(0, 0, 0, 0.34); */
    background-color: rgba(0, 0, 0, 0.34);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal_content {
    margin-right: 20px;
    margin-top: 20px;
    border: 1px solid red;
    width: 100%;
    height: max-content;
  }
  .modal_video {
    width: 100%;
  }
  .play_triangle {
    width: 24px;
    height: 24.1px;
    transition: all 0.5s ease;
  }
}
@media all and (max-width: 767px) {
  .wCard_wrapper {
    width: 100%;
    height: 190px;
    border: 1px solid grey;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
  .wCard_img {
    height: 100%;
    width: 100%;
    transition: all 0.5s ease;
  }
  .img_hover {
    transform: scale(1.1);
  }
  .wCard_cover {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.17);
  }
  .wCard_play {
    width: 66px;
    height: 66px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* font-size: 90px; */
    /* color: rgba(0, 0, 0, 0.34); */
    background-color: rgba(0, 0, 0, 0.34);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal_content {
    margin-right: 20px;
    margin-top: 20px;
    width: 100%;
    height: max-content;
  }
  .modal_video {
    width: 100%;
  }
  .play_triangle {
    width: 27px;
    height: 27.5px;
    transition: all 0.5s ease;
  }
}
