html {
    font-size: 10px;
}

.body {
    font-size: 1.0rem;
    font-family: Pretendard;
    /* height: 100vh; */
    margin: 37px 0px 130px 0px;
}

.eventListContainer {
    max-width: 1400px;
    margin: 0 auto;
}

.firstEvent {
    margin-top: 36px;
    margin-bottom: 80px;
}

.firstEventHeader {
    font-size: 2.8rem;
    font-weight: bold;
    color: #1f299c;
    margin-bottom: 22px;
}

.firstEventHeader span {
    font-family: Pretendard;
    margin-left: 24px;
    border-left: 1px solid #1f299c;
    padding-left: 24px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
}

.firstEventCard {
    display: flex;
    justify-content: center;
}

.firstEventImage_wrapper {
    width: 833px;
    /* height: 398px; */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center;
}

.firstEventImage {
    width: 100%;
}

.firstEventLikeIconArea {
    position: absolute;
    top: 10px;
    cursor: pointer;
    border: 5px solid transparent;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: solid 1px #bdbfd9;
    right: 60px;
    margin-right: 8px;
}

.firstEventLikeIcon {
    width: 24px;
}

.firstEventAlarmIconArea {
    position: absolute;
    top: 10px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: solid 1px #bdbfd9;
    right: 10px;
}

.firstEventAlarmIcon {
    width: 24px;
}

.firstEventDetails {
    padding: 49px 66px 33px 46px;
}

.firstEventTitle {
    font-size: 3.5rem;
    font-weight: 600;
    letter-spacing: -0.18px;
    color: #1f299c;
    margin-bottom: 33px;
}

.firstEventDescription {
    font-size: 1.6rem;
    letter-spacing: -0.08px;
    color: rgba(31, 41, 156, 0.8);
    opacity: 0.8;
    margin-bottom: 48px;
}

.firstEventDateTimeImg {
    width: 24px;
    height: 24px;
    margin-right: 13px;
}

.firstEventDateTime {
    font-size: 2.0rem;
    font-weight: 600;
    letter-spacing: -0.1px;
    color: #1f299c;
    display: flex;
    align-items: center;
    margin-bottom: 49px;
}

.firstEventRegisterBtn {
    width: 100%;
    height: 51px;
    background-color: #1f299c;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 1.85;
    color: #fff;
    cursor: pointer;
    border: 1px solid #1f299c;
}

.firstEventRegisterBtn:hover {
    opacity: 0.9;
}

.firstEventRegisterBtn_mobile {
    display: none;
}

.active {
    background-color: #1f299c;
}

/* =================== */

.upcomingEvents {
    /* margin-bottom: 133px; */
    /* height: 396px; */
}

.upcomingEventHeader {
    font-size: 2.8rem;
    font-weight: bold;
    color: #1f299c;
    margin-bottom: 21px;
}

.upcomingEventCardArea {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

/* .upcomingEventCardArea > *:not(:nth-child(4n)) { */
.upcomingEventCardArea>* {
    /* margin-right: 20px;
    margin-bottom: 20px; */
    margin: 6px;
}

.upcomingEventCard {
    padding: 11px 14px 31px 34px;
    position: relative;
    width: 338px;
    /* height: 338px; */
    border-radius: 8px;
    border: solid 1px #bebfcc;
    box-sizing: border-box;
}


.upcomingEventIconArea {
    display: flex;
    justify-content: end;
}

.upcomingEventLikeIconArea {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: solid 1px #bdbfd9;
    margin-right: 5px;
    background-color: #fff;
}

.upcomingEventLikeIcon {
    width: 18px;
}

.upcomingEventAlarmIconArea {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: solid 1px #bdbfd9;
    background-color: #fff;
}

.upcomingEventAlarmIcon {
    width: 17px;
}

.upcomingEventDetails {
    /* padding: 0px 0px 20px 21px; */
}

.upcomingEventTitle {
    font-family: Butler;
    font-size: 3.1rem;
    font-weight: bold;
    line-height: 0.92;
    color: #111;
    margin-bottom: 20px;
}

.upcomingEventDescription {
    font-size: 1.5rem;
    line-height: 1.47;
    letter-spacing: -0.08px;
    color: #282828;
    margin-bottom: 43px;
}

.upcomingEventDateTime {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.08px;
    color: #282828;
    display: flex;
    align-items: center;
    margin-bottom: 41px;
}

.upcomingEventDateTimeImg {
    width: 24px;
    height: 24px;
    margin-right: 9px;
}

.upcomingEventRegisterBtn {
    width: 267px;
    height: 42px;
    background-color: #1f299c;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.97;
    color: #fff;
    cursor: pointer;
    border: 1px solid #1f299c;
}

.upcomingEventRegisterBtn:hover {
    opacity: 0.9;
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1024px)*/
@media all and (min-width:768px) and (max-width:1024px) {
    .firstEvent {
        margin-top: 23px;
        margin-bottom: 50px;
        padding: 0 60px;
    }

    .firstEventHeader {
        font-size: 1.9rem;
        margin-bottom: 12px;
    }

    .firstEventHeader span {
        font-family: Pretendard;
        margin-left: 20px;
        border-left: 1px solid #1f299c;
        padding-left: 20px;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .firstEventImage_wrapper {
        width: 532px;
    }

    .firstEventImage {
        width: 100%;
    }

    .firstEventDetails {
        padding: 22px 31px 19px 36px;
    }

    .firstEventTitle {
        font-size: 2.4rem;
        margin-bottom: 11px;
    }

    .firstEventDescription {
        font-size: 1.2rem;
        margin-bottom: 27px;
    }

    .firstEventDateTime {
        font-size: 1.6rem;
        margin-bottom: 29px;
    }

    .firstEventRegisterBtn {
        width: 100%;
        height: 36px;
    }

    .firstEvent {
        height: auto;
    }

    .upcomingEvents {
        padding: 0 60px;
    }

    .upcomingEventHeader {
        font-size: 1.9rem;
        margin-bottom: 14px;
    }

    /* .upcomingEventCardArea > *:not(:nth-child(4n)) { */
    .upcomingEventCardArea>* {
        margin: 4px;
    }

    .upcomingEventCard {
        width: 213px;
        padding: 7px 7px 15px 16px;
    }

    .upcomingEventLikeIconArea {
        width: 28px;
        height: 28px;
    }

    .upcomingEventLikeIcon {
        width: 12.5px;
    }

    .upcomingEventAlarmIconArea {
        width: 28px;
        height: 28px;
    }

    .upcomingEventAlarmIcon {
        width: 12.5px;
    }

    .upcomingEventTitle {
        font-size: 2.1rem;
        margin-bottom: 10px;
    }

    .upcomingEventDescription {
        font-size: 1.0rem;
        margin-bottom: 27px;
    }

    .upcomingEventDetails {
        /* padding: 0px; */
    }

    .upcomingEventDateTimeImg {
        width: 12.7px;
        height: 12.7px;
    }

    .upcomingEventDateTime {
        margin-bottom: 26.7px;
        font-size: 1.0rem;
    }

    .upcomingEventRegisterBtn {
        width: 100%;
        height: 29px;
        font-size: 1.2rem;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {

    .body {
        font-size: 1.5rem;
    }

    .firstEventHeader {
        display: none;
    }

    .firstEvent {
        height: auto;
        margin-bottom: 60px;
    }

    .firstEventHeader {}

    .firstEventCard {
        display: grid;
    }

    .firstEventImage_wrapper {
        order: 2;
        width: 100%;
        /* height: 256px; */
    }

    .firstEventImage {
        width: 100%;
    }

    .firstEvfirstEventDetailsentImage {
        order: 1;
    }

    .firstEventDetails {
        padding: 0px;
        width: 335px;
    }

    .firstEventTitle {
        margin-bottom: 15px;
        font-size: 2.6rem;
    }

    .firstEventDescription {
        font-size: 1.2rem;
    }

    .firstEventDateTime {
        margin-bottom: 28px;
        font-size: 1.7rem;
    }

    .firstEventRegisterBtn {
        display: none;
    }


    .firstEventLikeIconArea {
        width: 40px;
        height: 40px;
        right: 50px;
    }

    .firstEventLikeIcon {
        width: 15.3px;
    }

    .firstEventAlarmIconArea {
        width: 40px;
        height: 40px;
        right: 13px;
    }

    .firstEventAlarmIcon {
        width: 17px;
    }


    .firstEventRegisterBtn_mobile {
        order: 3;
        display: block;
        height: 43px;
        background-color: #1f299c;
        font-size: 1.5rem;
        font-weight: 500;
        color: #fff;
        cursor: pointer;
        border: 1px solid #1f299c;
        margin-top: 6px;
    }

    .upcomingEventHeader {
        width: 335px;
        margin: 0 auto 21px auto;
    }

    .upcomingEventCardArea {
        justify-content: center;
    }

    .upcomingEventCardArea>* {
        margin: 0 10px 20px 10px;
    }

    .upcomingEventCardArea>*:not(:nth-child(4n)) {
        margin: 0 10px 20px 10px;
    }

    .upcomingEventCard {
        width: 280px;
        height: auto;
        padding: 10px 12px 19px 24px;
        box-sizing: border-box;
    }

    .upcomingEventLikeIconArea {
        width: 33px;
        height: 33px;
    }

    .upcomingEventLikeIcon {
        width: 12.5px;
    }

    .upcomingEventAlarmIconArea {
        width: 33px;
        height: 33px;
    }

    .upcomingEventAlarmIcon {
        width: 12px;
    }

    .upcomingEventTitle {
        margin-bottom: 17px;
        font-size: 2.8rem;
    }

    .upcomingEventDescription {
        margin-bottom: 36px;
        font-size: 1.2rem;
    }

    .upcomingEventDetails {
        padding: 0px;
    }

    .upcomingEventDateTimeImg {
        width: 15px;
        height: 15px;
    }

    .upcomingEventDateTime {
        margin-bottom: 35px;
        font-size: 1.3rem;
    }

    .upcomingEventRegisterBtn {
        width: 100%;
        height: 37px;
        font-size: 1.6rem;
    }
}