/* PC (해상도 1024px)*/
@media all and (min-width: 1024px) {

  /* card */
  .card_wrapper {
    /* width: 441px; */
    max-width: 439px;
    height: 315px;
    border-radius: 8px;
    border: 1px solid #bebfcc;
    overflow: hidden;
  }

  .card_top {
    height: 236px;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .fav_btn {
    position: absolute;
    top: 18px;
    right: 18px;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    border: 1px solid #bdbfd9;
    background-color: #fff;
    cursor: pointer;
    z-index: 100;
  }

  .btn_img {
    width: 18px;
    height: 16px;
  }

  .card_img_wrapper {
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
  }

  .card_img {
    width: 100%;
    height: 100%;
  }

  .card_background {
    height: 100%;
    transition: all 0.5s ease;
  }

  .card_background:hover {
    transform: scale(1.1);
  }

  .card_title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 186px;
  }

  .card_title>.title_1 {
    width: 100%;
    font-family: LibreBaskerville;
    font-size: 33px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    text-align: center;
    color: #111;
    -webkit-background-clip: text;
    background-clip: text;
  }

  .card_title>.title_2 {
    width: 100%;
    margin-top: 25px;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: -0.08px;
    text-align: center;
    color: #111;
  }

  .card_bot {
    height: 79px;
    /* width: 100%; */
    padding: 19px 19px 22px 17px;
    border-top: 1px solid #bebfcc;
  }

  .info_container {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .info {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .info_img_container {
    height: 40px;
    width: 40px;
    border-radius: 5px;
    border: 1px solid #bebfcc;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info_img {
    height: 20px;
    width: 20px;
  }

  .info_datetime {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.38;
    letter-spacing: -0.08px; */
    text-align: left;
    color: #282828;
    margin-left: 12px;
  }

  .viewmore_btn {
    height: 100%;
    width: 174px;
    background-color: #fff;
    border: 1px solid #1f299c;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.97;
    letter-spacing: normal;
    text-align: center;
    color: #1f299c;
    cursor: pointer;
    transition: all 0.5s;
  }

  .viewmore_btn:hover {
    background-color: #1f299c;
    color: #fff;
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {

  /* card */
  .card_wrapper {
    /* width: 441px; */
    max-width: 290px;
    height: 210px;
    border-radius: 8px;
    border: 1px solid #bebfcc;
    overflow: hidden;
  }

  .card_top {
    height: 157px;
    position: relative;
    overflow: hidden;
  }

  .card_background {
    height: 100%;
    transition: all 0.5s ease;
  }

  .card_background:hover {
    transform: scale(1.1);
  }

  .fav_btn {
    position: absolute;
    top: 12px;
    right: 12px;
    height: 27px;
    width: 28px;
    border-radius: 4px;
    border: 1px solid #bdbfd9;
    background-color: #fff;
    cursor: pointer;
    z-index: 100;
  }

  .btn_img {
    width: 14.3px;
    height: 14px;
  }

  .card_img_wrapper {
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
  }

  .card_img {
    width: 100%;
    height: 100%;
  }

  .card_title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 110px;
  }

  .card_title>.title_1 {
    width: 100%;
    font-family: LibreBaskerville;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: -0.33px;
    text-align: center;
    color: #111;
    -webkit-background-clip: text;
    background-clip: text;
  }

  .card_title>.title_2 {
    width: 100%;
    margin-top: 25px;
    font-family: Pretendard;
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.21px;
    text-align: center;
    color: #111;
  }

  .card_bot {
    /* height: 53px; */
    /* width: 100%; */
    padding: 10px 12px 13px 10px;
    border-top: 1px solid #bebfcc;
  }

  .info_container {
    width: 100%;
    height: 29px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .info {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .info_img_container {
    height: 27px;
    width: 27px;
    border-radius: 4px;
    border: 1px solid #bebfcc;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info_img {
    height: 14.6px;
    width: 14.6px;
  }

  .info_datetime {
    font-family: Pretendard;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.32px;
    text-align: left;
    color: #282828;
    margin-left: 12px;
  }

  .viewmore_btn {
    height: 100%;
    width: 117px;
    background-color: #fff;
    border: 1px solid #1f299c;
    font-family: Pretendard;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.73;
    letter-spacing: -0.37px;
    text-align: center;
    color: #1f299c;
    cursor: pointer;
    transition: all 0.5s;
  }

  .viewmore_btn:hover {
    background-color: #1f299c;
    color: #fff;
  }
}

@media all and (max-width: 767px) {

  /* card */
  .card_wrapper {
    width: 100%;
    height: 239px;
    border-radius: 5px;
    border: 1px solid #bebfcc;
    overflow: hidden;
  }

  .card_top {
    height: 179px;
    position: relative;
    overflow: hidden;
  }

  .card_background {
    height: 100%;
    transition: all 0.5s ease;
  }

  .card_background:hover {
    transform: scale(1.1);
  }

  .fav_btn {
    position: absolute;
    top: 14px;
    right: 14px;
    height: 31px;
    width: 31px;
    border-radius: 4px;
    border: 1px solid #bdbfd9;
    background-color: #fff;
    cursor: pointer;
    z-index: 100;
  }

  .btn_img {
    width: 16.4px;
    height: 16px;
  }

  .card_img_wrapper {
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
  }

  .card_img {
    width: 100%;
    height: 100%;
  }

  .card_title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 142px;
  }

  .card_title>.title_1 {
    width: 100%;
    font-family: LibreBaskerville;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.85;
    letter-spacing: -0.39px;
    text-align: center;
    color: #111;
    -webkit-background-clip: text;
    background-clip: text;
  }

  .card_title>.title_2 {
    width: 100%;
    margin-top: 16px;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.28px;
    text-align: center;
    color: #111;
  }

  .card_bot {
    height: 34px;
    /* width: 100%; */
    padding: 12px 19px 14px 17px;
    border-top: 1px solid #bebfcc;
  }

  .info_container {
    width: 100%;
    height: 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .info {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .info_img_container {
    height: 31px;
    width: 31px;
    border-radius: 4px;
    border: 1px solid #bebfcc;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info_img {
    height: 16px;
    width: 16px;
  }

  .info_datetime {
    padding-left: 9px;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: -0.42px;
    text-align: left;
    color: #282828;
  }

  .viewmore_btn {
    height: 100%;
    width: 133px;
    background-color: #fff;
    border: 1px solid #1f299c;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: -0.44px;
    text-align: center;
    color: #1f299c;
    cursor: pointer;
    transition: all 0.5s;
  }

  .viewmore_btn:hover {
    background-color: #1f299c;
    color: #fff;
  }
}