.content_wrapper {
  /* border: 1px solid red; */
  width: 100%;
  /* max-height: 552px; */
  margin-top: 43px;
  margin-bottom: 25px;
  overflow: hidden;
  /* overflow-x: auto; */
}
.content_container {
  /* border: 1px solid blue; */
  margin: 0 auto;
  /* margin-top: 20px; */
  width: 82.5%;
}
.video_container {
  /* border: 1px solid blue; */
  width: 100%;
  height: 100%;
}
.video_wrapper {
  /* border: 1px solid red; */
  height: inherit;
}
.video {
  width: 100%;
  /* height: 504px; */
  /* border: 1px solid red; */
  position: relative;
  /* cursor: pointer; */
}
.play_btn {
  height: 85px;
  width: 85px;
  background-color: #fff;
  opacity: 1;
  border-radius: 100%;
  border: none;
  outline: none;
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.1s ease;
  cursor: pointer;
}
.invisible {
  opacity: 0;
}
.play_triangle {
  width: 23px;
  height: 26px;
}
.video_title {
  /* border: 1px solid black; */
  /* height: 21px; */
  font-family: Pretendard;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #1f299c;
  margin-top: 20px;
}
/* .subContainer {
  height: 500px;
  width: 100%;
  border: 1px solid green;
} */
@media all and (max-width: 1023px) {
  .content_wrapper {
    /* border: 1px solid red; */
    width: 100%;
    /* max-height: 552px; */
    margin-top: 30px;
    /* margin-bottom: 25px; */
    overflow: hidden;
    /* overflow-x: auto; */
  }
  .content_container {
    /* border: 1px solid blue; */
    margin: 0 auto;
    /* margin-top: 20px; */
    width: 100%;
  }
  .video {
    /* width: 100%; */
    /* height: 504px; */
    position: relative;
  }
  @media all and (max-width: 767px) {
    .video_title {
      /* font-size: 2vw; */
      font-size: 12px;
      margin-top: 10px;
    }
  }
}
