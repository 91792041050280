.info2_1 {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.info2_2 {
  display: flex;
  align-items: center;
}

.button {
  cursor: pointer;
}

.Message {
  font-family: Pretendard;
}

.input {
  height: 100%;
  border: none;
  outline: none;
}

.select,
.select>div {
  border: none !important;
  outline: none !important;
  height: 100% !important;
  align-items: center;
  /* padding: 0 !important; */
}

.select>div input {
  height: 100% !important;
}

.select>div :global .ant-select-selection-placeholder {
  color: #757575;
}

@media all and (min-width: 1384px) {
  .main_wrapper {
    width: 100%;
    max-width: 1413px;
    padding-top: 54px;
    padding-bottom: 182px;
    /* border: 3px solid red; */
    margin: 0 auto;
  }

  .title {
    font-family: LibreBaskerville !important;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
  }

  .contents {
    display: flex;
    margin-top: 30px;
    height: 615px;
    width: 100%;
    max-width: 1413px;
    justify-content: space-between;
  }

  .map {
    width: 450px;
    background-color: #f2f5ff;
    display: flex;
    flex-direction: column;
    padding: 126px 134px 187px 135px;
    gap: 89px;
  }

  .sns_title_wrap {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .sns_title {
    height: 26px;
    line-height: 26px;
    font-family: LibreBaskerville;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
  }

  .sns_description {
    height: 14px;
    line-height: 14px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
  }

  .sns_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sns_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11px;
  }

  .sns_item_icon_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    background-color: #1f299c;
    border-radius: 50%;
  }

  .sns_item_title {
    height: 15px;
    line-height: 15px;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1f299c;
  }

  .info {
    width: 647px;
  }

  .info_body {
    margin-left: 7px;
  }

  .header_1 {
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
  }

  .header_2 {
    display: flex;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 70px;
  }

  .header_2_line {
    width: 80px;
    height: 1px;
    background-color: #1f299c;
    margin-left: 57px;
    margin-right: 23px;
  }

  .header_2_text {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #1f299c;
  }

  .contents_line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .input_icon {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #f15928;
    margin-right: 8px;
  }

  .input_header {
    display: flex;
    align-items: center;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #2c34a0;
  }

  .input_box {
    margin-top: 7px;
    width: 268px;
    height: 22px;
    border: solid 1px #bdbfd9;
    padding: 14px 21px;
    display: flex;
  }

  .input_box2 {
    margin-top: 7px;
    max-width: 598px;
    width: 100%;
    height: 110px;
    border: solid 1px #bdbfd9;
    padding: 14px 20px;
    display: flex;
  }

  .input_img_2 {
    height: 23px;
    width: 28px;
    margin-right: 12px;
  }

  .button {
    width: 309px;
    height: 50px;
    line-height: 50px;
    color: white;
    text-align: center;
    font-size: 19px;
    background-color: #1f299c;
    margin-left: 8px;
    margin-top: 32px;
  }

  .info2 {
    width: calc(100% - 110px);
    background-color: #f0f0f0;
    height: 88px;
    margin-top: 47px;
    display: flex;
    align-items: center;
    padding: 0px 55px;
    justify-content: space-evenly;
  }

  .w_circle {
    width: 56px;
    height: 56px;
    border-radius: 56px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }

  .info2_text {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #222;
    margin-right: 54px;
  }

  .info2_text2 {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #222;
  }

  .info2_text3 {
    background-color: black;
    width: 1px;
    height: 20px;
    margin: 0 22px;
  }

  .input_img {
    margin-right: 12px;
  }

  .Message {
    resize: none;
    outline: none;
    font-size: 16px;
    width: 100%;
    padding-top: 3px;
  }

  .Email,
  .Phone,
  .Name,
  .Country {
    font-size: 16px;
    outline: none;
    width: 100%;
  }
}

@media all and (min-width: 925px) and (max-width: 1383px) {
  .main_wrapper {
    width: 100%;
    max-width: 904px;
    padding-top: 42px;
    padding-bottom: 95px;
    /* border: 3px solid red; */
    margin: 0 auto;
    min-height: 70vh;
  }

  .title {
    height: 19px;
    font-family: LibreBaskerville;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
    /* border: 1px solid red; */
  }

  .contents {
    display: flex;
    margin-top: 16px;

    justify-content: space-between;
  }

  .map {
    width: 297px;
    height: 199px;
    background-color: #f2f5ff;
    display: flex;
    flex-direction: column;
    padding: 64px 56px 87px 57px;
    gap: 62.5px;
  }

  .sns_title_wrap {
    display: flex;
    flex-direction: column;
    gap: 10.5px;
  }

  .sns_title {
    height: 24px;
    line-height: 24px;
    font-family: LibreBaskerville;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
  }

  .sns_description {
    height: 14px;
    line-height: 14px;
    font-family: Pretendard;
    font-size: 11.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
  }

  .sns_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sns_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }

  .sns_item_icon_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #1f299c;
    border-radius: 50%;
  }

  .sns_item_title {
    height: 15px;
    line-height: 15px;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1f299c;
  }


  .info {
    width: 457px;
  }

  .header_1 {
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
  }

  .header_2 {
    display: flex;
    align-items: center;
    margin-top: 14px;
    margin-bottom: 25px;
  }

  .header_2_line {
    width: 51px;
    height: 1px;
    background-color: #1f299c;
    margin-left: 34px;
    margin-right: 12px;
  }

  .header_2_text {
    font-family: Pretendard;
    font-size: 11.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #1f299c;
  }

  .contents_line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
  }

  .input_icon {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #f15928;
    margin-right: 8px;
  }

  .input_header {
    display: flex;
    align-items: center;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #2c34a0;
  }

  .input_box {
    margin-top: 4px;
    width: 180px;
    height: 20px;
    border: solid 1px #bdbfd9;
    padding: 14px 21px;
    display: flex;
  }

  .input_img {
    margin-right: 12px;
  }

  .input_box2 {
    margin-top: 7px;
    max-width: 417px;
    width: 100%;
    height: 108px;
    border: solid 1px #bdbfd9;
    padding: 14px 20px;
    display: flex;
  }

  .input_img_2 {
    height: 23px;
    width: 28px;
    margin-right: 12px;
  }

  .button {
    width: 459px;
    height: 50px;
    line-height: 50px;
    color: white;
    text-align: center;
    font-size: 19px;
    background-color: #1f299c;

    margin-top: 32px;
  }

  .info2 {
    width: calc(100% - 40px);
    background-color: #f0f0f0;
    height: 88px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    justify-content: space-evenly;
  }

  .w_circle {
    width: 56px;
    height: 56px;
    border-radius: 56px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .info2_text {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #222;
    margin-right: 24px;
    width: 396px;
  }

  .info2_text2 {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #222;
  }

  .info2_text3 {
    background-color: black;
    width: 1px;
    height: 20px;
    margin: 0 9px;
  }

  .Message {
    resize: none;
    outline: none;
    font-size: 16px;
    width: 100%;
    padding-top: 3px;
  }

  .Email,
  .Phone,
  .Name,
  .Country {
    font-size: 16px;
    outline: none;
    width: 100%;
  }
}

@media all and (min-width: 300px) and (max-width: 924px) {
  .main_wrapper {
    width: 100%;
    padding-top: 26.5px;
    padding-bottom: 90px;
    /* margin-bottom: 90px; */
    /* border: 3px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    height: 35px;
    font-family: LibreBaskerville;
    font-size: 35px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
    /* border: 1px solid red; */
  }

  .contents {
    display: flex;
    margin-top: 20px;

    justify-content: space-between;
    flex-direction: column;
  }

  .map {
    width: 275px;
    height: 190px;
    background-color: #f2f5ff;
    display: flex;
    flex-direction: column;
    padding: 46px 30px 50px 30px;
    gap: 51px;
  }

  .sns_title_wrap {
    display: flex;
    flex-direction: column;
    gap: 14.5px;
  }

  .sns_title {
    height: 26px;
    line-height: 26px;
    font-family: LibreBaskerville;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
  }

  .sns_description {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.43;
    text-align: left;
    color: #1f299c;
  }

  .sns_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sns_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5.5px;
  }

  .sns_item_icon_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    background-color: #1f299c;
    border-radius: 50%;
  }

  .sns_item_title {
    height: 15px;
    line-height: 15px;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1f299c;
  }


  .info {
    width: 100%;
  }

  .header_1 {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
    margin-top: 20px;
  }

  .header_2 {
    display: flex;
    align-items: center;
    margin-top: 14px;
    margin-bottom: 29px;
    justify-content: flex-end;
    width: 335px;
  }

  .header_2_line {
    width: 36px;
    height: 1px;
    background-color: #1f299c;
    margin-left: 34px;
    margin-right: 10.5px;
  }

  .header_2_text {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #1f299c;
    width: 212px;
  }

  .contents_line {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .input_div {
    width: 335px;
    margin-bottom: 20px;
  }

  .input_icon {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #f15928;
    margin-right: 8px;
  }

  .input_header {
    display: flex;
    align-items: center;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #2c34a0;
  }

  .input_box {
    margin-top: 5px;
    width: 309px;
    height: 20px;
    border: solid 1px #bdbfd9;
    padding: 14px 13px;
    display: flex;
  }

  .input_img {
    margin-right: 12px;
  }

  .input_box2 {
    margin-top: 7px;
    max-width: 307px;
    width: 100%;
    height: 108px;
    border: solid 1px #bdbfd9;
    padding: 10px 13px;
    display: flex;
  }

  .input_img_2 {
    height: 23px;
    width: 28px;
    margin-right: 12px;
  }

  .button {
    width: 337px;
    height: 50px;
    line-height: 50px;
    color: white;
    text-align: center;
    font-size: 19px;
    background-color: #1f299c;
    border-radius: 3px;

    margin-top: 32px;
  }

  .info2 {
    width: 283px;
    height: 206px !important;
    background-color: #f0f0f0;
    height: 88px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    padding: 34px 32px 43px 20px;
    flex-direction: column;
    justify-content: space-between;
  }

  .w_circle {
    width: 56px;
    height: 56px;
    border-radius: 56px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 17px;
  }

  .info2_text {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #222;
    width: 209px;
    line-height: 25px;
  }

  .info2_text2 {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #222;
    line-height: 30px;
  }

  .info2_text3 {
    background-color: black;
    width: 1px;
    height: 20px;
    margin: 0 9px;
    display: none;
  }

  .Message {
    resize: none;
    outline: none;
    font-size: 16px;
    width: 100%;
    padding-top: 3px;
  }

  .Email,
  .Phone,
  .Name,
  .Country {
    font-size: 14px;
    outline: none;
    width: 100%;
  }

  .info2_2 {
    display: flex;
    align-items: flex-start;
    width: 207px;
    flex-direction: column;
  }
}