.wrapper {
  width: 100%;
  /* height: 100%; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  overflow: hidden;
}

/* PC (해상도 1024px)*/
@media all and (min-width: 1024px) {

  /* body */
  .body {
    width: 100%;
    height: 100%;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid blue;
  }

  .main_wrapper {
    width: 1413px;
    max-width: 1413px;
    padding-top: 54px;
    padding-bottom: 182px;
    /* border: 3px solid red; */
    margin: 0 auto;
  }

  .title {
    font-family: LibreBaskerville;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
  }

  .title>span {
    font-family: Pretendard;
    margin-left: 24px;
    border-left: 1px solid #1f299c;
    padding-left: 24px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
  }

  .content {
    margin-top: 40px;
    /* border: 1px solid black; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
    /* grid-template-rows: repeat(auto-fit, minmax(300px, 1fr)); */
    /* grid-template-columns: 1fr 1fr 1fr; */
    /* grid-auto-rows: 100px; */
    row-gap: 35px;
    column-gap: 20px;
  }

  @media all and (min-width: 1318px) and (max-width: 1420px) {
    .main_wrapper {
      width: 100%;
      padding-top: 54px;
      padding-bottom: 182px;
      margin: 0 auto;
    }
  }

  @media all and (min-width: 1024px) and (max-width: 1317px) {
    .main_wrapper {
      width: 926px;
      padding-top: 54px;
      padding-bottom: 182px;
      margin: 0 auto;
    }
  }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023px) {

  /* body */
  .body {
    width: 100%;
    height: 100%;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid blue;
  }

  .main_wrapper {
    width: 904px;
    max-width: 904px;
    padding-top: 42px;
    padding-bottom: 95px;
    /* border: 3px solid red; */
    margin: 0 auto;
    min-height: 70vh;
  }

  .title {
    height: 33px;
    font-family: LibreBaskerville;
    font-size: 31px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
    /* border: 1px solid red; */
  }

  .title>span {
    font-family: Pretendard;
    margin-left: 20px;
    border-left: 1px solid #1f299c;
    padding-left: 20px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
  }

  .content {
    margin-top: 21px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(282px, 1fr));
    column-gap: 10px;
    row-gap: 30px;
  }

  @media all and (min-width: 884px) and (max-width: 919px) {
    .main_wrapper {
      width: 100%;
    }
  }

  @media all and (min-width: 767px) and (max-width: 883px) {
    .main_wrapper {
      width: 610px;
      margin: 0 auto;
    }
  }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {

  /* body */
  .body {
    width: 100%;
    height: 100%;
    margin-top: 57px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid blue; */
  }

  .main_wrapper {
    width: 335px;
    max-width: 335px;
    padding-top: 43.5px;
    padding-bottom: 90px;
    /* margin-bottom: 90px; */
    /* border: 3px solid red; */
    margin: 0 auto;
  }

  .title {
    font-family: LibreBaskerville;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
    display: flex;
    flex-direction: column;
    gap: 9px;
  }

  .title>span {
    font-family: Pretendard;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
  }

  .content {
    margin-top: 17px;
    display: flex;
    /* grid-auto-rows: 100px; */
    flex-direction: column;
    gap: 30px;
  }
}