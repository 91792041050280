/* PC (해상도 1024px)*/
@media all and (min-width: 1024px) {
  .container {
    max-width: 530px;
  }

  .banner {
    margin: 98px 0 88px;
  }

  .title {
    font-size: 39px;
  }

  .description {
    font-size: 18px;
    line-height: 1.78;
    margin-top: 45px;
  }

  .form {
    row-gap: 30px;
  }

  .dot {
    width: 6px;
    height: 6px;
  }

  .item_title {
    font-size: 17px;
  }

  .item_body {
    max-width: 530px;
    margin-top: 10px;
  }

  .input {
    padding: 0 16px;
    width: calc(100% - 32px);
  }

  .column_grid {
    grid-template-columns: 1fr 150px;
  }

  .button_area {
    margin-bottom: 170px;
  }

  .register {
    row-gap: 15px;
    margin: 40px 0 170px;
  }

  .register_comment {
    font-size: 15px;
  }

  .singin {
    row-gap: 22px;
    margin: 14px 0 107px;
  }

  .option {
    row-gap: 36px;
  }

  .password {
    font-size: 14px;
  }

  .maintain {
    column-gap: 11px;
    font-size: 15px;
  }

  .maintain_chk {
    width: 20px;
    height: 20px;
  }

  .signup {
    column-gap: 9px;
  }

  .signup_comment {
    font-size: 17px;
  }

  .signup_btn {
    font-size: 17px;
    font-weight: 500;
  }

  .email {
    margin: 40px 0 340px;
  }

  .success_comment {
    font-size: 21px;
    margin-top: 57px;
  }

  .success {
    margin: 52px 0 414px;
  }

  .success_banner {
    margin-top: 98px;
  }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023px) {
  .container {
    max-width: 445px;
  }

  .banner {
    margin: 61px 0 55px;
  }

  .title {
    font-size: 33px;
  }

  .description {
    font-size: 15px;
    line-height: 1.67;
    margin-top: 38px;
  }

  .form {
    row-gap: 24px;
  }

  .dot {
    width: 5px;
    height: 5px;
  }

  .item_title {
    font-size: 14px;
  }

  .item_body {
    max-width: 445px;
    margin-top: 6px;
  }

  .input {
    padding: 0 14px;
    width: calc(100% - 28px);
  }

  .column_grid {
    grid-template-columns: 1fr 123px;
  }

  .button_area {
    margin-bottom: 123px;
  }

  .register {
    row-gap: 6px;
    margin: 27px 0 123px;
  }

  .register_comment {
    font-size: 14px;
  }

  .singin {
    row-gap: 22px;
    margin: 14px 0 70px;
  }

  .option {
    row-gap: 36px;
  }

  .password {
    font-size: 14px;
  }

  .maintain {
    column-gap: 11px;
    font-size: 15px;
  }

  .maintain_chk {
    width: 20px;
    height: 20px;
  }

  .signup {
    column-gap: 9px;
  }

  .signup_comment {
    font-size: 17px;
  }

  .signup_btn {
    font-size: 17px;
    font-weight: 500;
  }

  .email {
    margin: 40px 0 340px;
  }

  .success_comment {
    font-size: 21px;
    margin-top: 57px;
  }

  .success {
    margin: 52px 0 414px;
  }

  .success_banner {
    margin-top: 61px;
  }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  .container {
    max-width: 335px;
  }

  .banner {
    margin: 40px 0 49px;
  }

  .title {
    font-size: 30px;
  }

  .description {
    font-size: 13px;
    line-height: 1.64;
    margin-top: 30px;
  }

  .form {
    row-gap: 20px;
  }

  .dot {
    width: 4px;
    height: 4px;
  }

  .item_title {
    font-size: 16px;
  }

  .item_body {
    max-width: 335px;
    margin-top: 5px;
  }

  .input {
    padding: 0 14px;
    width: calc(100% - 28px);
  }

  .column_grid {
    grid-template-columns: 1fr 95px;
  }

  .button_area {
    margin-bottom: 96px;
  }

  .register {
    row-gap: 6px;
    margin: 23px 0 96px;
  }

  .register_comment {
    font-size: 14px;
  }

  .singin {
    row-gap: 21px;
    margin: 8px 0 70px;
  }

  .option {
    row-gap: 23px;
  }

  .password {
    font-size: 13px;
  }

  .maintain {
    column-gap: 10px;
    font-size: 14px;
  }

  .maintain_chk {
    width: 18px;
    height: 18px;
  }

  .signup {
    column-gap: 6px;
  }

  .signup_comment {
    font-size: 16px;
  }

  .signup_btn {
    font-size: 16px;
  }

  .email {
    margin: 25px 0 340px;
  }

  .success_comment {
    font-size: 16px;
    margin-top: 16px;
  }

  .success {
    margin: 55px 0 400px;
  }

  .success_banner {
    margin-top: 40px;
  }
}

/* 공통 */
.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  text-align: center;
}

.title {
  font-family: LibreBaskerville;
  font-weight: bold;
  color: #1f299c;
}

.description {
  font-family: Pretendard;
  color: #1f299c;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
}

.item_header {
  display: flex;
  align-items: center;
  column-gap: 9px;
}

.dot {
  border-radius: 100%;
  background-color: #bdbfd9;
}

.dot.require {
  background-color: #f15928;
}

.item_title {
  font-family: Pretendard;
  color: #2c34a0;
}

.item_body {
  width: 100%;
}

.input_container {
  position: relative;
  width: calc(100% - 2px);
  height: 48px;
  border-width: 1px;
  border-style: solid;
  border-color: #bdbfd9;
  transition-timing-function: linear;
  transition-duration: 100ms;
  transition-property: border-color;
}

.input_container:hover,
.input_container:has(input:focus),
.input_container:has(input:not(:placeholder-shown)) {
  border-color: #1f299c;
}

.input_container:has(input.error) {
  border-color: #ff390b !important;
}

.input {
  height: 100%;
  border: none;
  outline: none;
}

.select,
.select>div {
  border: none !important;
  outline: none !important;
  height: 100% !important;
  align-items: center;
}

.select>div input {
  height: 100% !important;
}

.input.placeholder {
  color: #757575;
}

.item_error_msg {
  position: absolute;
  bottom: -19px;
  left: 15px;
  font-family: Pretendard;
  font-size: 14px;
  color: #ff390b;
}

.row_grid {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 4px;
}

.password_grid {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr auto;
  row-gap: 4px;
}

.column_grid {
  width: 100%;
  display: grid;
  column-gap: 4px;
}

.button {
  width: 100%;
  height: 100%;
  background-color: #1f299c;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 100ms;
  transition-property: background-color;
}

.button:disabled {
  cursor: default !important;
}

.button:hover {
  background-color: #424997;
}

.button:active {
  background-color: #1f299c;
}

.button:disabled {
  background-color: #bdbfd9;
}

.button_area {
  width: 100%;
  height: 53px;
}

.register {
  width: 100%;
  display: grid;
  grid-template-rows: 53px auto;
}

.register_comment {
  font-family: Pretendard;
  color: #111;
  display: flex;
  align-items: center;
  column-gap: 9px;
}

.singin {
  width: 100%;
  display: grid;
  grid-template-rows: 53px auto;
}

.option {
  width: 100%;
  margin-top: 10px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 36px;
  font-family: Pretendard;
}

.password {
  color: #6d73ba;
  display: flex;
  justify-content: flex-end;
}

.password>p {
  cursor: pointer;
  border-bottom: 1px solid #6d73ba;
}

.maintain {
  display: flex;
  align-items: center;
  color: #2c34a0;
}

.maintain_chk {
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #1f299c;
}

.maintain_chk.check {
  background-image: url("/public/img/common/chk.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.signup {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: Pretendard;
}

.signup_comment {
  color: #1f299c;
}

.signup_btn {
  color: #1f299c;
  border-bottom: 1px solid #6d73ba;
  cursor: pointer;
}

.email {
  width: 100%;
  height: 53px;
}

.success_comment {
  font-family: Pretendard;
  color: #1f299c;
}

.success {
  width: 100%;
  height: 53px;
}

.success_banner {
  text-align: center;
}

.visible_icon {
  position: absolute;
  top: 0;
  right: 21px;
  height: 100%;
  display: flex;
  align-items: center;
}

.visible_icon>svg {
  cursor: pointer;
}

.step_wrapper {
  height: 145px;
  width: 100%;
  margin: 33px 0 40px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step_container {
  justify-content: center;
  display: grid !important;
}

.step_line {
  width: 100%;
  height: 2px;
  background-color: #c9c9c9;
}

.step_item {
  position: relative;
  display: flex;
  justify-content: center;
}

.step_round {
  border-radius: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: LibreBaskerville;
  color: #999;
}

.step_round.active {
  background-color: #1f299c;
  color: #fff;
}

.step_comment {
  position: absolute;
  font-family: Pretendard;
  white-space: nowrap;
  color: #999;
}

.step_comment.active {
  color: #1f299c;
  font-weight: 500;
}

.step_comment.black {
  color: #111111;
}

/* PC (해상도 1024px)*/
@media all and (min-width: 1024px) {
  .step_container {
    height: calc(100% - 20px);
    margin-bottom: 20px;
    grid-template-columns: auto 70px auto 70px auto 70px auto;
    /* grid-template-columns: auto 70px auto 70px auto; */
  }

  .step_round {
    width: 64px;
    height: 64px;
    font-size: 25px;
  }

  .step_comment {
    bottom: -25px;
    font-size: 14px;
    line-height: 1.14;
  }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023px) {
  .step_container {
    height: calc(100% - 20px);
    margin-bottom: 20px;
    grid-template-columns: auto 50px auto 50px auto 50px auto;
    /* grid-template-columns: auto 50px auto 50px auto; */
  }

  .step_round {
    width: 64px;
    height: 64px;
    font-size: 20px;
  }

  .step_comment {
    bottom: -25px;
    font-size: 12px;
    line-height: 1.64;
  }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  .step_container {
    height: calc(100% - 20px);
    margin-bottom: 20px;
    grid-template-columns: auto 40px auto 40px auto 40px auto;
    /* grid-template-columns: auto 40px auto 40px auto; */
  }

  .step_round {
    width: 48px;
    height: 48px;
    font-size: 20px;
  }

  .step_comment {
    bottom: -25px;
    font-size: 10px;
    line-height: 1.64;
  }
}

.password_comment_article {
  padding: 0 15px;
  width: calc(100% - 30px);
}

.password_comment {
  font-family: Pretendard;
  font-size: 14px;
  color: #9a9bae;
}

.authcode_article {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}

.authcode_article>article {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.authcode_resend_btn {
  font-weight: bold;
  cursor: pointer;
  color: #1f299c;
}

.authcode_time {
  font-weight: bold;
  color: #f26031;
}