/* PC (해상도 1025px)*/
:root {
    --newsletterColor: #000000;
}

@media all and (min-width:1025px) {
    .newsletter_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 1412px;
        padding: 61px 100px 133px;
        margin: 0 auto;
        gap: 42px;
    }

    .newsletter_title_wrapper {
        display: flex;
        flex-direction: column;
        gap: 14px;
    }

    .newsletter_title {
        height: 25px;
        font-family: LibreBaskerville;
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 25px;
        letter-spacing: -0.14px;
        text-align: left;
        color: #1f299c;
    }

    .newsletter_title>span {
        font-family: Pretendard;
        margin-left: 24px;
        border-left: 1px solid #1f299c;
        padding-left: 24px;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .newsletter_title_explain {
        height: 17px;
        opacity: 0.8;
        font-family: Pretendard;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 17px;
        letter-spacing: -0.08px;
        text-align: left;
        color: #1f299c;
    }

    .newsletter_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 94px;
        column-gap: 20px;
        width: 100%;
    }

    .newsletter_item {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 338px;
    }

    .newsletter_item_img_wrapper {
        position: relative;
        overflow: hidden;
        border-radius: 8px;
    }

    .newsletter_item_img {
        width: 100%;
        transition: all 0.5s ease;
    }

    .newsletter_item_img:hover {
        transform: scale(1.1);
    }

    .newsletter_item_like {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 38px;
        height: 38px;
        border-radius: 5px;
        border: solid 1px #a8abc0;
        background-color: #fff;
        top: 12px;
        right: 18px;
        cursor: pointer;
    }

    .newsletter_item_like>img {
        width: 18px;
        height: 16px;
    }

    .newsletter_item_title {
        height: 14px;
        font-family: Pretendard;
        font-size: 19px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 14px;
        letter-spacing: normal;
        text-align: left;
        color: #f15928;
    }

    .newsletter_item_explain {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .newsletter_detail_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 1412px;
        padding: 60px 20px 193px;
        margin: 0 auto;
        gap: 62px;
    }

    .newsletter_detail_title_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        /* height: 340px; */
        border-radius: 8px;
        background-color: #fff;
        position: relative;
    }

    .newsletter_detail_title_wrapper>img {
        width: 100%;
    }

    .newsletter_detail_btn_area {
        position: absolute;
        display: flex;
        gap: 18px;
        margin-bottom: 20px;
    }

    .newsletter_detail_like_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 5px;
        border: solid 1px #bdbfd9;
        cursor: pointer;
    }

    .newsletter_detail_like_btn>img {
        width: 24px;
        height: 22px;
    }

    .newsletter_detail_download_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: 224px;
        height: 50px;
        border-radius: 5px;
        background-color: #1f299c;
        cursor: pointer;
    }

    .newsletter_detail_download_btn>img {
        width: 23px;
        height: 23px;
    }

    .newsletter_detail_download_btn>span {
        height: 13px;
        font-family: Pretendard;
        font-size: 17px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 13px;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }

    .newsletter_detail_content_wrapper {
        display: flex;
        flex-direction: column;
        gap: 47px;
        width: 100%;
    }

    .newsletter_detail_content_list {
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 100%;
    }

    .newsletter_detail_content_item {
        display: flex;
        gap: 60px;
        width: 100%;
        padding-bottom: 48px;
        border-bottom: 1px solid #c0c0c0;
    }

    .newsletter_detail_content_item_title {
        width: 275px;
        font-family: Pretendard;
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.24;
        letter-spacing: normal;
        text-align: left;
        /* color: #c8500e; */
        color: var(--newsletterColor);
    }

    .newsletter_detail_content_item_img {
        width: calc(100% - 275px);
    }

    .newsletter_detail_card_area {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 70px;
    }

    .newsletter_detail_card_area_title {
        height: 25px;
        font-family: Pretendard;
        font-size: 33px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 25px;
        letter-spacing: normal;
        text-align: left;
        /* color: #c8500e; */
        color: var(--newsletterColor);
    }

    .newsletter_detail_card_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 63px;
        column-gap: 23px;
        width: 100%;
    }

    .newsletter_detail_card_list_vol_1 {
        display: flex;
        /* flex-wrap: wrap;
        justify-content: center; */
        row-gap: 63px;
        column-gap: 23px;
        width: 100%;

        .newsletter_detail_card_item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 50%;
        }

        .newsletter_detail_card_text {
            margin-bottom: 34px
        }

        .newsletter_detail_card_img {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }

    /* .newsletter_detail_card_list_vol_1 .newsletter_detail_card_item {
        display: flex;
        flex-direction: column;
        width: 50%;
    } */

    .newsletter_detail_card_item {
        display: flex;
        flex-direction: column;
        width: 455px;
    }

    .newsletter_detail_card_item_large {
        display: flex;
        flex-direction: column;
        width: 934px;
    }

    .newsletter_detail_card_title_wrapper {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        width: 100%;
        margin-top: 22px;
    }

    .newsletter_detail_card_country_img {
        width: 43px;
        /* height: 29px; */
    }

    .newsletter_detail_card_title {
        /* height: 23px; */
        font-family: Pretendard;
        font-size: 22px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        /* line-height: 23px;
        letter-spacing: -1.04px; */
        text-align: left;
        color: #000;
    }

    .newsletter_detail_card_text {
        margin-top: 24px;
        font-family: Pretendard;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: -0.17px;
        text-align: left;
        color: #555;
    }

    .newsletter_detail_news_area {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 35px;
        margin-top: 40px;
        padding-top: 45px;
        border-top: 1px solid #c0c0c0;
    }

    .newsletter_detail_news_area_title {
        height: 25px;
        font-family: Pretendard;
        font-size: 33px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 25px;
        letter-spacing: normal;
        text-align: left;
        /* color: #c8500e; */
        color: var(--newsletterColor);
    }

    .newsletter_detail_news_list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 63px;
        width: 100%;
    }

    .newsletter_detail_news_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 25px;
        width: 100%;
    }

    .newsletter_detail_news_text {
        font-family: Pretendard;
        font-size: 21px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.21px;
        text-align: left;
        color: #555;
    }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1024px)*/
@media all and (min-width:768px) and (max-width:1024px) {
    .newsletter_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 1022px;
        padding: 22.5px 40px 89.5px;
        margin: 0 auto;
        gap: 30px;
    }

    .newsletter_title_wrapper {
        display: flex;
        flex-direction: column;
        gap: 1px;
    }

    .newsletter_title {
        height: 23px;
        font-family: LibreBaskerville;
        font-size: 19px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 23px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .newsletter_title>span {
        font-family: Pretendard;
        margin-left: 20px;
        border-left: 1px solid #1f299c;
        padding-left: 20px;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .newsletter_title_explain {
        height: 14px;
        opacity: 0.8;
        font-family: Pretendard;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 14px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .newsletter_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 67px;
        column-gap: 10px;
        width: 100%;
    }

    .newsletter_item {
        display: flex;
        flex-direction: column;
        width: 294px;
    }

    .newsletter_item_img_wrapper {
        position: relative;
        overflow: hidden;
        border-radius: 8px;
    }

    .newsletter_item_img {
        width: 100%;
        transition: all 0.5s ease;
    }

    .newsletter_item_img:hover {
        transform: scale(1.1);
    }

    .newsletter_item_like {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 38px;
        height: 38px;
        border-radius: 5px;
        border: solid 1px #a8abc0;
        background-color: #fff;
        top: 12px;
        right: 18px;
        cursor: pointer;
    }

    .newsletter_item_like>img {
        width: 18px;
        height: 16px;
    }

    .newsletter_item_title {
        margin-top: 13px;
        height: 18px;
        font-family: Pretendard;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 18px;
        letter-spacing: -0.08px;
        text-align: left;
        color: #f15928;

    }

    .newsletter_item_explain {
        margin-top: 7px;
        font-family: Pretendard;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }


    .newsletter_detail_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 903px;
        padding: 40px 60px 108px;
        margin: 0 auto;
        gap: 39px;
    }

    .newsletter_detail_title_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        /* height: 340px; */
        border-radius: 8px;
        background-color: #fff;
        position: relative;
    }

    .newsletter_detail_title_wrapper>img {
        width: 100%;
    }

    .newsletter_detail_btn_area {
        position: absolute;
        display: flex;
        gap: 12px;
        margin-bottom: 15px;
    }

    .newsletter_detail_like_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 29px;
        height: 29px;
        border-radius: 5px;
        border: solid 1px #bdbfd9;
        cursor: pointer;
    }

    .newsletter_detail_like_btn>img {
        width: 17px;
        height: 16px;
    }

    .newsletter_detail_download_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 143px;
        height: 31px;
        border-radius: 5px;
        background-color: #1f299c;
        cursor: pointer;
    }

    .newsletter_detail_download_btn>img {
        width: 18px;
        height: 19px;
    }

    .newsletter_detail_download_btn>span {
        height: 13px;
        font-family: Pretendard;
        font-size: 11px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 13px;
        letter-spacing: -0.18px;
        text-align: center;
        color: #fff;
    }

    .newsletter_detail_content_wrapper {
        display: flex;
        flex-direction: column;
        gap: 29.5px;
        width: 100%;
    }

    .newsletter_detail_content_list {
        display: flex;
        flex-direction: column;
        gap: 29.5px;
        width: 100%;
    }

    .newsletter_detail_content_item {
        display: flex;
        gap: 25px;
        width: 100%;
        padding-bottom: 29.5px;
        border-bottom: 1px solid #c0c0c0;
    }

    .newsletter_detail_content_item_title {
        width: 188px;
        font-family: Pretendard;
        font-size: 19px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.32;
        letter-spacing: -0.19px;
        text-align: left;
        /* color: #c8500e; */
        color: var(--newsletterColor);
    }

    .newsletter_detail_content_item_img {
        width: calc(100% - 188px);
    }

    .newsletter_detail_card_area {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 38px;
    }

    .newsletter_detail_card_area_title {
        height: 25px;
        font-family: Pretendard;
        font-size: 21px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 25px;
        letter-spacing: -0.21px;
        text-align: left;
        /* color: #c8500e; */
        color: var(--newsletterColor);
    }

    .newsletter_detail_card_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 45px;
        column-gap: 16px;
        width: 100%;
    }

    .newsletter_detail_card_list_vol_1 {
        display: flex;
        /* flex-wrap: wrap;
        justify-content: center; */
        row-gap: 63px;
        column-gap: 23px;
        width: 100%;

        .newsletter_detail_card_item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 50%;
        }

        .newsletter_detail_card_text {
            margin-bottom: 34px
        }

        .newsletter_detail_card_img {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }

    .newsletter_detail_card_item {
        display: flex;
        flex-direction: column;
        width: 290px;
    }

    .newsletter_detail_card_item_large {
        display: flex;
        flex-direction: column;
        width: 603px;
    }

    .newsletter_detail_card_title_wrapper {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        width: 100%;
        margin-top: 16px;
    }

    .newsletter_detail_card_country_img {
        width: 28px;
        height: 18px;
    }

    .newsletter_detail_card_title {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.35px;
        text-align: left;
        color: #000;
    }

    .newsletter_detail_card_text {
        margin-top: 14px;
        font-family: Pretendard;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: -0.06px;
        text-align: left;
        color: #555;
    }

    .newsletter_detail_news_area {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 19px;
        margin-top: 20px;
        padding-top: 25px;
        border-top: 1px solid #c0c0c0;
    }

    .newsletter_detail_news_area_title {
        height: 25px;
        font-family: Pretendard;
        font-size: 21px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 25px;
        letter-spacing: -0.21px;
        text-align: left;
        /* color: #c8500e; */
        color: var(--newsletterColor);
    }

    .newsletter_detail_news_list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 45px;
        width: 100%;
    }

    .newsletter_detail_news_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 25px;
        width: 100%;
    }

    .newsletter_detail_news_text {
        font-family: Pretendard;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: -0.06px;
        text-align: left;
        color: #555;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    .newsletter_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100% - 40px);
        padding: 43px 20px 63.5px;
        gap: 30px;
    }

    .newsletter_title_wrapper {
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    .newsletter_title {
        /* height: 35px; */
        font-family: LibreBaskerville;
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 35px;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
        display: flex;
        flex-direction: column;
        gap: 9px;
    }

    .newsletter_title>span {
        font-family: Pretendard;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .newsletter_title_explain {
        opacity: 0.8;
        font-family: Pretendard;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .newsletter_list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 50px;
        width: 100%;
    }

    .newsletter_item {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .newsletter_item_img_wrapper {
        position: relative;
        overflow: hidden;
        border-radius: 8px;
    }

    .newsletter_item_img {
        width: 100%;
        transition: all 0.5s ease;
    }

    .newsletter_item_img:hover {
        transform: scale(1.1);
    }

    .newsletter_item_like {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 31px;
        height: 31px;
        border-radius: 5px;
        border: solid 1px #a8abc0;
        background-color: #fff;
        top: 9px;
        right: 11px;
        cursor: pointer;
    }

    .newsletter_item_like>img {
        width: 17px;
        height: 17px;
    }

    .newsletter_item_title {
        margin-top: 12px;
        height: 25px;
        font-family: Pretendard;
        font-size: 21px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 25px;
        letter-spacing: -0.11px;
        text-align: left;
        color: #f15928;
    }

    .newsletter_item_explain {
        margin-top: 7px;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: left;
        color: #1f299c;
    }

    .newsletter_detail_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100% - 40px);
        margin: 20px 20px 70px;
        gap: 41px;
    }

    .newsletter_detail_title_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        position: relative;
    }

    .newsletter_detail_title_wrapper>img {
        width: 100%;
    }

    .newsletter_detail_btn_area {
        position: fixed;
        display: flex;
        height: 50px;
        width: calc(100% - 40px);
        gap: 4px;
        padding: 8px 20px;
        background-color: #fff;
        border-top: 2px solid #eee;
        bottom: 0;
    }

    .newsletter_detail_like_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 5px;
        border: solid 1px #bdbfd9;
        cursor: pointer;
    }

    .newsletter_detail_like_btn>img {
        width: 26px;
        height: 26px;
    }

    .newsletter_detail_download_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: calc(100% - 50px);
        height: 100%;
        border-radius: 5px;
        background-color: #1f299c;
        cursor: pointer;
    }

    .newsletter_detail_download_btn>img {
        width: 29px;
        height: 29px;
    }

    .newsletter_detail_download_btn>span {
        height: 19px;
        font-family: Pretendard;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 19px;
        letter-spacing: -0.26px;
        text-align: center;
        color: #fff;
    }

    .newsletter_detail_content_wrapper {
        display: flex;
        flex-direction: column;
        gap: 29.5px;
        width: 100%;
    }

    .newsletter_detail_content_list {
        display: flex;
        flex-direction: column;
        gap: 29.5px;
        width: 100%;
    }

    .newsletter_detail_content_item {
        display: flex;
        flex-direction: column;
        gap: 25px;
        width: 100%;
        padding-bottom: 29.5px;
        border-bottom: 1px solid #c0c0c0;
    }

    .newsletter_detail_content_item_title {
        font-family: Pretendard;
        font-size: 22px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.45;
        letter-spacing: normal;
        text-align: left;
        /* color: #c8500e; */
        color: var(--newsletterColor);
    }

    .newsletter_detail_content_item_img {
        width: 100%;
    }

    .newsletter_detail_card_area {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 35px;
    }

    .newsletter_detail_card_area_title {
        height: 26px;
        font-family: Pretendard;
        font-size: 22px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 26px;
        letter-spacing: normal;
        text-align: left;
        /* color: #c8500e; */
        color: var(--newsletterColor);
    }

    .newsletter_detail_card_list {
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 100%;
    }

    .newsletter_detail_card_item {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .newsletter_detail_card_title_wrapper {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        width: 100%;
        margin-top: 22px;
    }

    .newsletter_detail_card_country_img {
        margin-top: 3px;
        width: 34px;
        height: 23px;
    }

    .newsletter_detail_card_title {
        font-family: Pretendard;
        font-size: 22px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.45;
        letter-spacing: -0.11px;
        text-align: left;
        color: #000;
    }

    .newsletter_detail_card_text {
        margin-top: 15px;
        font-family: Pretendard;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: -0.09px;
        text-align: left;
        color: #555;
    }

    .newsletter_detail_news_area {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 35px;
        margin-top: 20px;
        padding-top: 25px;
        border-top: 1px solid #c0c0c0;
    }

    .newsletter_detail_news_area_title {
        height: 26px;
        font-family: Pretendard;
        font-size: 22px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 26px;
        letter-spacing: normal;
        text-align: left;
        /* color: #c8500e; */
        color: var(--newsletterColor);
    }

    .newsletter_detail_news_list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 40px;
        width: 100%;
    }

    .newsletter_detail_news_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 25px;
        width: 100%;
    }

    .newsletter_detail_news_text {
        font-family: Pretendard;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.09px;
        text-align: left;
        color: #555;
    }
}