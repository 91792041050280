@media all and (min-width: 1024px) {
  .table_wrapper {
    width: 100%;
    /* border: 1px solid black; */
  }
  .table_wrapper > div {
    /* padding: 0 14px; */
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ceced6;
  }

  .table_wrapper > .col_index {
    height: 50px;
    width: 100%;
    background-color: #1f299c;
    color: #fff;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 5.38; */
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .table_wrapper > .col_white {
    height: fit-content;
    min-height: 57px;
    padding: 10.5px 0;
    width: 100%;
    background-color: #fff;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 2.06; */
    letter-spacing: normal;
    text-align: left;
    color: #282828;
    display: flex;
    align-items: center;
  }
  .table_wrapper > .col_blue {
    height: fit-content;
    min-height: 76px;
    padding: 10.5px 0;
    width: 100%;
    background-color: rgba(242, 243, 255, 0.7);
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 2.06; */
    letter-spacing: normal;
    text-align: center;
    color: #282828;
    display: flex;
    align-items: center;
  }
  .table_wrapper > div > div:not(:first-child) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .table_wrapper > div > .row_topic {
    /* border: 1px solid white; */
    height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
    text-align: left;
  }
  .table_wrapper > div > .row_topic > div {
    margin-left: 14px;
  }
  .table_wrapper > div > .row_speaker {
    /* border: 1px solid white; */
    width: 25%;
  }
  .table_wrapper > div > .row_speaker > .speaker_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .table_wrapper > div > .row_speaker > .speaker_info > .position {
    height: 15px;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.7;
    letter-spacing: normal;
    text-align: center;
    color: #555;
  }
  .table_wrapper > div > .row_time {
    /* border: 1px solid white; */
    width: 10%;
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .table_wrapper {
    width: 100%;
    /* border: 1px solid black; */
  }
  .table_wrapper > div {
    /* padding: 0 14px; */
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ceced6;
  }

  .table_wrapper > .col_index {
    height: 32px;
    width: 100%;
    background-color: #1f299c;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 5.38; */
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .table_wrapper > .col_white {
    height: fit-content;
    min-height: 36px;
    padding: 10.5px 0;
    width: 100%;
    background-color: #fff;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.06px;
    text-align: left;
    color: #282828;
    display: flex;
    align-items: center;
  }
  .table_wrapper > .col_blue {
    height: fit-content;
    min-height: 49px;
    padding: 10.5px 0;
    /* width: 100%; */
    background-color: rgba(242, 243, 255, 0.7);
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.06px;
    text-align: left;
    color: #282828;
    display: flex;
    align-items: center;
  }
  .table_wrapper > div > div:not(:first-child) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .table_wrapper > div > .row_topic {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: left;
  }
  .table_wrapper > div > .row_topic > div {
    margin-left: 14px;
  }
  .table_wrapper > div > .row_speaker {
    width: 25%;
  }
  .table_wrapper > div > .row_speaker > .speaker_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
  }
  .table_wrapper > div > .row_speaker > .speaker_info > .position {
    height: 15px;
    font-family: Pretendard;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 7; */
    letter-spacing: -0.1px;
    text-align: center;
    color: #555;
  }
  .table_wrapper > div > .row_time {
    /* border: 1px solid white; */
    width: 10%;
  }
}
@media all and (max-width: 767px) {
  .table_wrapper {
    width: 100%;
    /* border: 1px solid black; */
  }
  .table_wrapper > div {
    /* padding: 0 14px; */
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ceced6;
  }

  .table_wrapper > .col_index {
    height: 50px;
    width: 100%;
    background-color: #1f299c;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 5.38; */
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .table_wrapper > .col_white {
    height: fit-content;
    min-height: 56px;
    padding: 10.5px 0;
    width: 100%;
    background-color: #fff;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.42; */
    letter-spacing: -0.06px;
    text-align: center;
    color: #282828;
    display: flex;
    align-items: center;
  }
  .table_wrapper > .col_blue {
    height: fit-content;
    min-height: 76.5px;
    padding: 10.5px 0;
    /* width: 100%; */
    background-color: rgba(242, 243, 255, 0.7);
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.42; */
    letter-spacing: -0.06px;
    text-align: center;
    color: #282828;
    display: flex;
    align-items: center;
  }
  .table_wrapper > div > div:not(:first-child) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .table_wrapper > div > .row_topic {
    width: 55%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: left;
  }
  .table_wrapper > div > .row_topic > div {
    margin-left: 14px;
  }
  .table_wrapper > div > .row_speaker {
    width: 25%;
  }
  .table_wrapper > div > .row_speaker > .speaker_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 83px;
    gap: 3px;
    overflow: hidden;
  }
  .table_wrapper > div > .row_speaker > .speaker_info > .position {
    height: fit-content;
    max-width: 81px;
    font-family: Pretendard;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: -0.1px;
    text-align: center;
    color: #555;
    margin-top: 2px;
  }
  .table_wrapper > div > .row_time {
    /* border: 1px solid white; */
    width: 15%;
  }
}
