@media all and (min-width: 1024px) {
  .card_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 285px;
    height: 332px; */
    /* border: 1px solid grey; */
  }

  .speaker_img {
    /* height: 239px;
    width: 100%; */
  }

  .speaker_name {
    height: 24px;
    margin-top: 15px;
    font-family: Pretendard;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.6;
    letter-spacing: normal;
    text-align: center;
    color: #1f299c;
    /* border: 1px solid red; */
  }

  .speaker_title {
    margin-top: 10px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: center;
    color: #111;
  }

  .speaker_country {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: center;
    color: #111;
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .card_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 183px;
    height: 216px; */
    /* border: 1px solid grey; */
  }

  .speaker_img {
    /* height: 154px;
    width: 100%; */
  }

  .speaker_name {
    height: 16px;
    margin-top: 8px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    letter-spacing: normal;
    text-align: center;
    color: #1f299c;
  }

  .speaker_title {
    margin-top: 6px;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #111;
  }

  .speaker_country {
    font-family: Pretendard;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #111;
  }
}

@media all and (max-width: 767px) {
  .card_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 239px;
    height: 268px; */
  }

  .speaker_img {
    /* height: 200px;
    width: 100%; */
  }

  .speaker_name {
    height: 18px;
    margin-top: 8px;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    letter-spacing: normal;
    text-align: center;
    color: #1f299c;
  }

  .speaker_title {
    margin-top: 8px;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #111;
  }

  .speaker_country {
    font-family: Pretendard;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #111;
  }
}