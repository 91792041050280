/* PC (해상도 1024px)*/
@media all and (min-width: 1024px) {
  .container {
    width: 100%;
    height: 100%;
    /* position: relative; */
  }
  .wrapper {
    width: 556px;
    margin-top: 82px;
    margin-left: 15%;
    /* position: absolute;
    top: 82px;
    left: 22%;
    transform: translateX(-22%); */
  }
  .header {
    font-size: 27px;
    color: #1f299c;
    font-weight: bold;
    font-family: LibreBaskerville;
    margin-bottom: 36px;
  }
  .body {
    padding-left: 25px;
  }
  .password {
    margin-top: 28px;
  }
  .password_header {
    font-size: 17px;
    color: #2c34a0;
    font-family: Pretendard;
    margin-bottom: 7px;
  }

  .password_body {
    width: 490px;
    height: 20px;
    border: 1px solid #bdbfd9;
    padding: 14px 22px 14px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .password_input {
    height: 20px;
    outline: none;
    font-size: 16px;
    width: 460px;
    background-color: white;
  }

  .password_img {
    height: 20px;
    width: 28px;
  }
  .button {
    width: 529px;
    height: 53px;
    background-color: #1f299c;
    line-height: 53px;
    text-align: center;
    margin-top: 50px;
    color: white;
    font-size: 19px;
    font-family: Pretendard;
    cursor: pointer;
  }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023px) {
  .container {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .wrapper {
    width: 417px;
    /* margin-top: 82px;
    margin-left: 194px; */
    position: absolute;
    top: 51.5px;
    /* left: 138.5px; */
    left: 42%;
    transform: translateX(-42%);
  }
  .header {
    font-size: 19px;
    color: #1f299c;
    font-weight: bold;
    font-family: LibreBaskerville;
    margin-bottom: 29px;
  }
  .password {
    margin-top: 20px;
  }
  .password_header {
    font-size: 13px;
    color: #2c34a0;
    font-family: Pretendard;
    margin-bottom: 6px;
  }

  .password_body {
    width: 390px;
    height: 26px;
    border: 1px solid #bdbfd9;
    padding: 6px 13px 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .password_input {
    height: 14px;
    outline: none;
    font-size: 12px;
    width: 365px;
    background-color: white;
  }

  .password_img {
    height: 16px;
    width: auto;
  }
  .button {
    width: 417px;
    height: 46px;
    background-color: #1f299c;
    line-height: 46px;
    text-align: center;
    margin-top: 20px;
    color: white;
    font-size: 17px;
    font-family: Pretendard;
    cursor: pointer;
  }
}
@media all and (max-width: 767px) {
  .container {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .wrapper {
    width: 335px;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
  .header {
    font-size: 28px;
    color: #1f299c;
    font-weight: bold;
    font-family: LibreBaskerville;
    margin-bottom: 18px;
    text-align: left;
  }
  .password {
    margin-top: 20px;
  }
  .password_header {
    font-size: 17px;
    color: #2c34a0;
    font-family: Pretendard;
    margin-bottom: 7px;
  }

  .password_body {
    width: 297px;
    height: 18px;
    border: 1px solid #bdbfd9;
    padding: 14px 23px 14px 13px;
    display: flex;
    justify-content: space-between;
  }
  .password_input {
    height: 18px;
    outline: none;
    font-size: 15px;
    width: 290px;
    line-height: 18px;
    background-color: white;
  }

  .password_img {
    height: 18px;
    width: auto;
  }
  .button {
    width: 335px;
    height: 53px;
    background-color: #1f299c;
    line-height: 53px;
    text-align: center;
    margin-top: 20px;
    color: white;
    font-size: 17px;
    font-family: Pretendard;
    cursor: pointer;
  }
}
