.menu_wrapper {
    margin-top: 58px;
    z-index: 999;
    display: flex;
    width: 100%;
    height: calc(100vh - 58px);
    height: calc(100dvh - 58px);
    position: fixed;
}

.menu_background {
    display: flex;
    width: 100%;
    height: 100%;
    /* -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px); */
    background-color: rgba(0, 0, 0, 0.45);
}

.menu_area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 70px);
    height: 100%;
    background-color: #fff;
}

.menu_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100%; */
}

.menu_list {
    display: flex;
    flex-direction: column;
}

.menu_list_item {
    display: flex;
    height: 54px;
    padding-left: 20px;
    align-items: center;
    border-bottom: 1px solid #1f299c;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
}

.sign_menu_area {
    display: flex;
    align-items: center;
    width: calc(100% - 20px);
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px
}

.sign_menu_item {
    height: 21px;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
    cursor: pointer;
}

.sign_menu_item:first-child {
    padding-right: 35.5px;
    border-right: 1px solid #1f299c;
}

.sign_menu_item:last-child {
    padding-left: 35.5px;
}

.menu_footer {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    width: calc(100% - 40px);
    /* height: 181px; */
    padding: 27px 20px 20px 20px;
    gap: 38px;
    background-color: #f2f6ff;
}

.menu_footer_title_area {
    display: flex;
    flex-direction: column;
    gap: 11px;
}

.menu_footer_title {
    font-family: LibreBaskerville;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
}

.menu_footer_explain {
    font-family: Pretendard;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
}

.menu_footer_register_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    background-color: #1f299c;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}