html {
    font-size: 10px;
}

.body {
    /* height: 100vh; */
    margin: 116px 0px 0px 0px;
    font-family: Pretendard;
    margin: 42px 0px 110px 0px;
}

.eventContainer {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
}

.eventArea {
    width: 900px;
    margin-right: 37px;
}

.eventCard {
    display: flex;
    justify-content: center;
}

.eventImageArea {
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center;
    margin-bottom: 50px;
    width: 100%;
}

.eventImage {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center;
    margin-bottom: 18px;
}

.eventImageSubArea {
    /* height: 42px; */
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 1.4rem;
    color: #111;
    flex-wrap: wrap;
}

.eventImageSubArea>div {
    display: flex;
    align-items: center;
    margin-right: 57px;
}



.eventImageSubCameraIcon {
    width: 26px;
    margin-right: 11px;
}

.eventImageSubYoutubeIcon {
    width: 26px;
    margin-right: 11px;
}


.eventImageSubStreamingIcon {
    width: 37px;
    margin-right: 11px;
}

.eventRegisterArea {}

.eventContent {
    padding: 46px 35px 42px 34px;
    border-radius: 8px;
    box-shadow: 0px 0 7px 0 rgba(0, 0, 0, 0.24);
    background-color: #fff;
    position: sticky;
    top: 123px;
    scroll-behavior: smooth;
}

.eventContent.modal {
    display: block;
}

.eventTitle {
    font-size: 2.5rem;
    font-weight: 600;
    letter-spacing: -0.18px;
    color: #1f299c;
    margin-bottom: 33px;

}

.eventDescription {
    font-size: 1.6rem;
    letter-spacing: -0.08px;
    color: rgba(31, 41, 156, 0.8);
    opacity: 0.8;
    margin-bottom: 48px;
}

.eventDateTimeImg {
    width: 24px;
    height: 24px;
    margin-right: 13px;
}

.eventDateTimeLabel,
.eventRegisterLabel {
    font-size: 1.4rem;
    color: #747474;
    margin-bottom: 10px;
}

.eventDateTime {
    font-size: 2.0rem;
    font-weight: 500;
    color: #111;
    margin-bottom: 43px;
}

.eventRegisterInputFormItem {
    margin-bottom: 18px;
}

.firstNmInputFormItem {
    width: 54%;
    margin-right: 1%;
}

.lastNmInputFormItem {
    width: 45%;
}

.eventRegisterInputValue {
    height: 45px;
    border: solid 1px #bdbfd9;
    border-radius: 0px;
    font-size: 1.4rem;
    padding: 0px 21px;
}

.timezoneInputValue {
    background-image: url("/public/img/register/timezone-icon.png");
    background-repeat: no-repeat;
    background-position: 20px 10px;
    background-size: 15px;
    padding: 0 0 0 42px;
    cursor: unset;
}

.active {
    background-color: #1f299c;
}

.eventRegisterInputIcon {
    width: 18px;
    margin-right: 10px;
}

.eventRegisterLikeIconArea {
    cursor: pointer;
    border-radius: 5px;
    border: solid 1px #bdbfd9;
    margin-right: 5px;
    background-color: #fff;
    position: relative;
    padding: 13px 12px 10px 12px;
}

.eventRegisterLikeIcon {
    width: 24px;
}

.eventRegisterAlarmIconArea {
    cursor: pointer;
    /* width: 40px;
    height: 40px; */
    border-radius: 5px;
    border: solid 1px #bdbfd9;
    margin-right: 5px;
    background-color: #fff;
    position: relative;
    padding: 9px 12px 8px 12px;
}

.eventRegisterAlarmIcon {
    width: 24px;
}

.eventRegisterBtnFormItem {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 30px;
}

.eventRegisterBtnValue {
    width: 100%;
    height: 51px;
    font-size: 1.7rem;
}

.eventRegisterBtnValue:hover {
    color: #fff;
}

.eventRegisterBtnValue.registered {
    background-color: #747474;
    pointer-events: none;
}

.eventRegisterBtnValue.after {
    background-color: #747474;
    pointer-events: none;
}


.eventDetails {}

.eventDetailsTitle {
    font-size: 3.0rem;
    color: #1f299c;
    padding-left: 10px;
    margin-bottom: 20px;
    font-weight: 600;
}

.eventDetailsDescription {
    font-size: 1.6rem;
    color: #555;
    padding-left: 10px;
    margin-bottom: 77px;
}

.eventDetailsTabs {
    display: flex;
    position: sticky;
    top: 80px;
    margin-bottom: 44px;
}

.eventDetailsTab {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33%;
    height: 60px;
    border: solid 1px #ddd;
    border-radius: 2px;
    background-color: #f7f7f7;
    font-size: 1.7rem;
    font-weight: 600;
    color: #111111;

}

.eventDetailsTab:hover {
    cursor: pointer;
    color: #1f299c;
    border-bottom: solid 2px #1f299c;
}

.eventDetailsTab.active {
    color: #1f299c;
    border-bottom: solid 2px #1f299c;
}

.timezoneLabel {
    padding-left: 5px;
}

.eventTimezoneArea {}

.eventTimezoneHeader {
    font-size: 2.8rem;
    font-weight: 500;
    color: #111;
    margin-bottom: 37px;
}

.eventTimezoneContent {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    margin-bottom: 65px;

}

.eventTimezoneContent>div {
    margin-right: 46px;
    margin-bottom: 9px;
}

.eventTimezone {
    display: flex;
    height: 38px;
    align-items: center;
    min-width: 250px;
}

.eventTimezone.end {
    margin-right: 0px;
}

.eventTimezoneName {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 100%;
    border-radius: 19px;
    background-color: rgba(223, 226, 255, 0.6);
    margin-right: 12px;
    font-size: 1.7rem;
    color: #111;
}

.eventTimezoneDate {
    font-size: 1.7rem;
    text-align: left;
    color: #111;
}

.eventTimezoneDate.border {
    padding-right: 27px;
    border-right: 1px solid #909090;
}

.eventSpeakersArea {
    margin-bottom: 65px;
}

.eventSpeakers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/* .eventSpeakers > div:not(:nth-child(3n)) { */
.eventSpeakers>div {
    margin-right: 10px;
}

.eventSpeakersHeader {
    font-size: 2.8rem;
    font-weight: 500;
    color: #111;
    margin-bottom: 21px;
}

.eventAgendasHeader {
    font-size: 2.8rem;
    font-weight: 500;
    color: #111;
    margin-bottom: 21px;
}

.moblieFooterArea {
    display: none;
}


/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1024px)*/
@media all and (min-width:768px) and (max-width:1024px) {
    .body {
        margin: 40px 0 82px 0;
    }

    .eventContainer {
        max-width: 900px;
        /* width: 900px; */
    }

    .eventArea {
        width: 582px;
        margin-right: 24px;
    }

    .eventImageArea {
        margin-bottom: 32px;
    }

    .eventImage {
        width: 100%;
        margin-bottom: 10px;
    }

    .eventImageSubArea {
        font-size: 1.3rem;
    }

    .eventImageSubArea>div {
        margin-right: 24.5px;
    }

    .eventImageSubCameraIcon {
        width: 18.6px;
        margin-right: 5.7px;
    }

    .eventImageSubYoutubeIcon {
        width: 17px;
        margin-right: 5.7px;
    }

    .eventImageSubStreamingIcon {
        width: 24px;
        margin-right: 5.7px;
    }

    .eventContent {
        padding: 23px 24px 21px 24px;
        width: 297px;
        box-sizing: border-box;
    }

    .eventTitle {
        font-size: 1.7rem;
        margin-bottom: 20px;
    }

    .eventDescription {
        font-size: 1.1rem;
        margin-bottom: 3px;
    }

    .eventDateTimeLabel,
    .eventRegisterLabel {
        font-size: 1.1rem;
        margin-bottom: 7px;
    }

    .eventDateTime {
        font-size: 1.4rem;
        margin-bottom: 23px;
    }

    .eventRegisterInputFormItem {
        margin-bottom: 10px;
    }

    .firstNmInputFormItem {
        width: 54%;
        margin-right: 1%;
    }

    .lastNmInputFormItem {
        width: 45%;
    }

    .eventRegisterInputValue {
        height: 37px;
        font-size: 1.2rem;
        padding: 0 11px;
    }

    .timezoneInputValue {
        background-size: 15px;
        padding: 0 0 0 42px;
    }

    /* .active {
        background-color: #1f299c;
    } */

    .eventRegisterInputIcon {
        width: 15px;
        margin-right: 6px;
    }

    .eventRegisterLikeIconArea {
        margin-right: 4px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .eventRegisterLikeIcon {
        width: 16.8px;
    }

    .eventRegisterAlarmIconArea {
        margin-right: 4px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .eventRegisterAlarmIcon {
        width: 16.5px;
    }

    .eventRegisterBtnFormItem {
        margin-top: 20px;
    }

    .eventRegisterBtnValue {
        width: 100%;
        height: 40px;
        font-size: 1.5rem;
    }

    .eventDetailsTitle {
        font-size: 2.3rem;
        padding-left: 0px;
        margin-bottom: 7px;
    }

    .eventDetailsDescription {
        font-size: 1.2rem;
        padding-left: 0px;
        margin-bottom: 40px;
    }

    .eventDetailsTabs {
        top: 25px;
        margin-bottom: 25px;
    }

    .eventDetailsTab {
        height: 40px;
        font-size: 1.5rem;
    }

    .timezoneLabel {
        display: none;
    }

    .eventTimezoneHeader {
        font-size: 1.5rem;
        margin-bottom: 18px;
        font-weight: 600;
    }

    .eventTimezoneContent {
        margin-bottom: 36px;
    }

    .eventTimezoneContent>div {
        margin-right: 9px;
        margin-bottom: 6px;
    }

    .eventTimezone {
        min-width: 83px;
        height: 26px;
    }

    .eventTimezoneName {
        width: 83px;
        margin-right: 5px;
        font-size: 1.4rem;
    }

    .eventTimezoneDate {
        font-size: 1.4rem;
    }

    .eventTimezoneDate.border {
        padding-right: 9px;
    }

    .eventSpeakersArea {
        margin-bottom: 40px;
    }

    /* .eventSpeakers > div:not(:nth-child(3n)) { */
    .eventSpeakers>div {
        margin: 5px;
    }

    .eventSpeakersHeader {
        font-size: 1.8rem;
        font-weight: 500;
        margin-bottom: 18px;
    }

    .eventAgendasHeader {
        font-size: 1.8rem;
        font-weight: 500;
        margin-bottom: 18px;
    }

    .moblieFooterArea {
        display: none;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    .body {
        margin: 20px 0 76px 0;
    }

    .eventContainer {
        display: block;
        max-width: 335px;
    }

    .eventArea {
        width: 335px;
        margin: 0;
    }

    .eventCard {
        display: block;
    }

    .eventImage {
        width: 100%;
        /* height: 340px; */
    }

    .eventImageSubCameraIcon {
        width: 18px;
        margin-right: 6px;
    }

    .eventImageSubYoutubeIcon {
        width: 17px;
        margin-right: 5px;
    }

    .eventImageSubStreamingIcon {
        width: 24px;
        margin-right: 5px;
    }

    .eventImageSubArea {
        font-size: 1.2rem;
        /* margin-right: 25px; */
    }

    .eventImageSubArea>div:not(:nth-child(2n)) {
        margin-right: 26px;
        margin-bottom: 10px;
    }

    .eventImageSubArea>div:nth-child(2n) {
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .eventDetailsTitle {
        padding: 0;
        font-size: 2.2rem;
        margin-bottom: 8px;
    }

    .eventDetailsDescription {
        padding: 0;
        font-size: 1.0rem;
        margin-bottom: 35px;
    }

    .eventDetailsTabs {
        margin-bottom: 26px;
        top: 57px;
    }

    .eventDetailsTab {
        font-size: 1.5rem;
    }

    .timezoneLabel {
        display: none;
    }

    .eventTimezoneHeader {
        font-size: 1.8rem;
        margin-bottom: 15px;
    }

    .eventTimezone {
        min-width: 150px;
    }

    .eventTimezone.end {
        margin-right: 13px;
    }

    .eventTimezoneContent {
        margin-bottom: 43px;
    }

    .eventTimezoneContent>div {
        margin-right: 13px;
    }

    .eventTimezoneName {
        width: 70px;
        border-radius: 13px;
        font-size: 1.4rem;
        margin-right: 4px;
    }

    .eventTimezoneDate {
        font-size: 1.3rem;
    }

    .eventTimezoneDate.border {
        border: none;
        padding: 0;
    }

    .eventSpeakers {
        margin-bottom: 17px;
        justify-content: center;
    }

    .eventSpeakersHeader {
        font-size: 1.8rem;
        margin-bottom: 15px;
    }

    .eventSpeakers>div {
        margin-bottom: 25px;
        margin-right: 0px;
    }

    .eventSpeakers>div:last-child {
        margin-bottom: 0px;
    }

    .eventAgendasHeader {
        font-size: 1.8rem;
        margin-bottom: 24px;
    }

    .moblieFooterArea {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        height: 68px;
        border-top: 1px solid #ddd;
        padding: 9px 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        box-sizing: border-box;
        z-index: 10;
    }

    .moblieFooterDragBar {
        border-bottom: 5px solid #dddddd;
        margin: 0 auto 21px auto;
        width: 30px;

    }

    .moblieFooterBtn {
        width: 100%;
        height: 100%;
        font-size: 1.7rem;
    }

    .moblieFooterBtn:hover {
        color: #fff;
    }

    .moblieFooterBtn.registered {
        background-color: #747474;
        pointer-events: none;
    }

    .moblieFooterBtn.after {
        background-color: #747474;
        pointer-events: none;
    }

    .moblieFooterLikeIconArea {
        cursor: pointer;
        border-radius: 5px;
        border: solid 1px #bdbfd9;
        margin-right: 5px;
        background-color: #fff;
        position: relative;
        padding: 13px 12px 10px 12px;
    }

    .moblieFooterLikeIcon {
        width: 24px;
    }

    .moblieFooterAlarmIconArea {
        cursor: pointer;
        border-radius: 5px;
        border: solid 1px #bdbfd9;
        margin-right: 5px;
        background-color: #fff;
        position: relative;
        padding: 9px 12px 8px 12px;
    }

    .moblieFooterAlarmIcon {
        width: 24px;
    }

    .eventRegisterArea {
        /* display: none; */
        position: fixed;
        bottom: 0px;
        left: 0;
        width: 100%;
        background: white;
        transition: transform 0.05s ease-in-out;
        transform: translateY(100%);
        z-index: 1;
    }

    .eventRegisterArea.visible {
        transform: translateY(0%);
    }

    .eventContent {
        padding: 9px 20px;
        position: unset;
        top: 0;
    }

    .eventContent.modal {
        display: none;
    }


    .eventTitle {
        font-size: 2.0rem;
        margin-bottom: 15px;
    }

    .eventDateTimeLabel {
        font-size: 1.2rem;
        margin-bottom: 4px;
    }

    .eventDateTime {
        font-size: 1.6rem;
        margin-bottom: 21px;
    }

    .eventRegisterLabel {
        display: none;
    }

    .eventRegisterInputFormItem {
        margin-bottom: 10px;
    }

    .eventRegisterBtnFormItem {
        margin-top: 22px;
    }

    .eventRegisterLikeIconArea {
        display: none;
    }

    .eventRegisterAlarmIconArea {
        display: none;
    }

    .overlay {
        /* background-color: rgba(0, 0, 0, 0.5); */
    }

    /* .overlay.open {
        display: none;
    } */
}