.wrapper {
  width: 100%;
  /* height: 100%; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  overflow: hidden;
}

/* PC (해상도 1024px)*/
@media all and (min-width: 1024px) {

  /* body */
  .main {
    width: 100%;
    height: 100%;
  }

  .main_wrapper {
    /* width: 96%; */
    /* padding: 61px 254px 183px 254px; */
    width: 1414px;
    max-width: 1414px;
    padding-top: 50px;
    padding-bottom: 186px;
    margin: 0 auto;
  }

  /* head box */
  .main_head {
    height: 428px;
    width: 100%;
    position: relative;
  }

  .main_head_content {
    width: 100%;
    height: fit-content;
    /* margin-top: 128px; */
    position: absolute;
    top: 128px;
  }

  .main_head_title {
    /* height: 535px; */
    height: 51px;
    width: 100%;
    font-family: Pretendard;
    font-size: 53px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    text-align: center;
    color: #0a0c2b;
  }

  .main_head_subtitle {
    height: 16px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #333;
    margin-top: 20px;
  }

  .main_head_datetime {
    height: 20px;
    font-family: Pretendard;
    font-size: 26px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    text-align: center;
    color: #111;
    margin-top: 35px;
  }

  .main_head_platform {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main_head_platform>img {
    height: 28px;
    width: 33px;
  }

  .main_head_platform>span {
    margin-left: 15px;
    font-family: Pretendard;
    font-size: 18.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.97;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  /* Speakers */
  .speakers_wrapper {
    margin-top: 56px;
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    padding-bottom: 47px;
    border-bottom: 1px solid #c0c0c0;
  }

  .speakers_title {
    height: 33px;
    font-family: Pretendard;
    font-size: 33px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.7;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .speakers_list {
    /* height: 332px; */
    width: 921px;
    margin-top: 6px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;
    row-gap: 30px;
  }

  .agenda_wrapper {
    margin-top: 42px;
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    /* padding-bottom: 47px; */
    /* border-bottom: 1px solid #c0c0c0; */
  }

  .agenda_title {
    height: 33px;
    font-family: Pretendard;
    font-size: 33px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.7;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .agenda_table {
    width: 914px;
  }

  .webinar_wrapper {
    width: 100%;
    margin-top: 39.5px;
    padding-bottom: 47px;
    border-bottom: 1px solid #c0c0c0;
  }

  .webinar_title {
    font-family: Pretendard;
    font-size: 33px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    text-align: center;
    color: #111;
  }

  .webinar_list {
    width: 100%;
    margin-top: 46px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(445px, 1fr));
    justify-items: center;
    column-gap: 24px;
    row-gap: 20px;
  }

  .modal_content {
    width: 100%;
    /* height: 100px; */
    border: 1px solid black;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .modal_video {
    width: 100%;
    height: 100%;
  }

  @media all and (min-width: 1024px) and (max-width: 1435px) {
    .main_wrapper {
      width: 1000px;
      max-width: 1000px;
    }

    .speakers_list {
      /* height: 332px; */
      width: 600px;
      margin-top: 6px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 30px;
      row-gap: 30px;
    }

    .agenda_table {
      width: 600px;
    }
  }

  /* @media all and (min-width: 1024px) and (max-width: 1325px) {
    .speakers_list {
      width: 285px;
      margin-top: 6px;
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 30px;
      row-gap: 30px;
    }
    .agenda_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 24px;
      padding-bottom: 47px;
      border-bottom: 1px solid #c0c0c0;
    }
    .agenda_table {
      margin-top: 30px;
      width: 100%;
    }
  } */
}

/* 태블릿 */
@media all and (min-width: 768px) and (max-width: 1023px) {

  /* body */
  .body {
    width: 100%;
    height: 100%;
    margin-top: 80px;
  }

  .main_wrapper {
    /* width: 96%; */
    /* padding: 61px 254px 183px 254px; */
    width: 903px;
    max-width: 903px;
    padding-top: 41px;
    padding-bottom: 75px;
    margin: 0 auto;
  }

  /* .title {
    font-family: LibreBaskerville;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
  } */
  /* .content {
    margin-top: 60 px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(439px, 1fr));
    row-gap: 35px;
    column-gap: 20px;
  } */
  /* head box */
  .main_head {
    height: 274px;
    width: 100%;
    position: relative;
  }

  .main_head_content {
    width: 100%;
    height: fit-content;
    /* margin-top: 128px; */
    position: absolute;
    top: 75px;
  }

  .main_head_title {
    /* height: 535px; */
    height: 33px;
    width: 100%;
    font-family: Pretendard;
    font-size: 34px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    text-align: center;
  }

  .main_head_subtitle {
    height: 11px;
    font-family: Pretendard;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: normal;
    text-align: center;
    margin-top: 11px;
  }

  .main_head_datetime {
    height: fit-content;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: -0.09px;
    text-align: center;
    margin-top: 17px;
  }

  .main_head_platform {
    margin-top: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main_head_platform>img {
    height: 24px;
    width: 25px;
  }

  .main_head_platform>span {
    margin-left: 8px;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: -0.06px;
    text-align: left;
  }

  /* Speakers */
  .speakers_wrapper {
    margin-top: 31px;
    display: flex;
    justify-content: space-between;
    padding-left: 14px;
    padding-bottom: 24.5px;
    border-bottom: 1px solid #c0c0c0;
  }

  .speakers_title {
    height: 22px;
    font-family: Pretendard;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.82;
    letter-spacing: -0.22px;
    text-align: left;
    color: #111;
  }

  .speakers_list {
    /* height: 332px; */
    width: 585px;
    margin-top: 6px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 18px;
    row-gap: 30px;
  }

  /* agenda */
  .agenda_wrapper {
    margin-top: 33.5px;
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    /* padding-bottom: 47px;
    border-bottom: 1px solid #c0c0c0; */
  }

  .agenda_title {
    height: 22px;
    font-family: Pretendard;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.82;
    letter-spacing: -0.22px;
    text-align: left;
    color: #111;
  }

  .agenda_table {
    width: 585px;
  }

  /* webinar */
  .webinar_wrapper {
    width: 100%;
    height: fit-content;
    margin-top: 39.5px;
    padding-bottom: 47px;
    border-bottom: 1px solid #c0c0c0;
  }

  .webinar_title {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.94;
    letter-spacing: normal;
    text-align: center;
    color: #111;
  }

  .webinar_list {
    width: 100%;
    margin-top: 46px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    justify-items: center;
    column-gap: 16px;
    row-gap: 20px;
  }

  @media all and (min-width: 768px) and (max-width: 924px) {
    .main_wrapper {
      width: 99%;
      max-width: none;
    }

    .main_head {
      width: 100%;
    }

    .webinar_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .webinar_list {
      width: 597px;
    }
  }
}

/* 모바일 */
@media all and (max-width: 767px) {

  /* body */
  .body {
    width: 100%;
    height: 100%;
    margin-top: 56px;
  }

  .main_wrapper {
    width: 335px;
    max-width: 335px;
    padding-top: 20px;
    padding-bottom: 63.5px;
    margin: 0 auto;
  }

  /* head box */
  .main_head {
    height: 220px;
    width: 100%;
    position: relative;
  }

  .main_head_content {
    width: 100%;
    height: fit-content;
    /* margin-top: 128px; */
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    position: absolute;
    top: 65px;
  }

  .main_head_title {
    /* height: 535px; */
    height: 21px;
    width: 100%;
    font-family: Pretendard;
    font-size: 21px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    text-align: center;
    color: #090c2b;
  }

  .main_head_subtitle {
    /* margin-top: 20px; */
    height: 8px;
    font-family: Pretendard;
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: -0.05px;
    text-align: center;
    color: #333;
    margin-top: 5px;
  }

  .main_head_datetime {
    height: 11px;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: -0.07px;
    text-align: center;
    color: #111;
    margin-top: 12px;
  }

  .main_head_platform {
    margin-top: 23px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main_head_platform>img {
    height: 17px;
    width: 18px;
  }

  .main_head_platform>span {
    margin-left: 5px;
    font-family: Pretendard;
    font-size: 9.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: -0.05px;
    text-align: center;
    color: #111;
  }

  /* Speakers */
  .speakers_wrapper {
    margin-top: 43px;
    padding-bottom: 21.5px;
    border-bottom: 1px solid #c0c0c0;
  }

  .speakers_title {
    height: 18px;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.85;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    margin-bottom: 15px;
    margin-left: 7px;
  }

  .speakers_list {
    /* height: 332px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }

  /* agenda */
  .agenda_wrapper {
    margin-top: 20.5px;
    /* padding-bottom: 35px;
    border-bottom: 1px solid #c0c0c0; */
  }

  .agenda_title {
    height: 18px;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.85;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    margin-left: 3px;
  }

  .agenda_table {
    width: 100%;
    margin-top: 24px;
  }

  /* webinar */
  .webinar_wrapper {
    width: 100%;
    margin-top: 33.5px;
    padding-bottom: 35px;
    border-bottom: 1px solid #c0c0c0;
  }

  .webinar_title {
    height: 16px;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;
    text-align: center;
    color: #111;
  }

  .webinar_list {
    width: 100%;
    margin-top: 34px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}