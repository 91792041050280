@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-weight: normal;
  src: url("./SpoqaHanSansNeo-Regular.woff") format("woff");
}

@font-face {
  font-family: "SpoqaHanSansNeo";
  font-weight: normal;
  src: url("./SpoqaHanSansNeo-Regular.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: normal;
  src: url("./Pretendard-Regular.woff") format("woff");
}

@font-face {
  font-family: "LibreBaskerville";
  font-weight: normal;
  src: url("./LibreBaskerville-Regular.ttf") format("truetype");
}