div {
  font-family: Pretendard;
}

@media all and (min-width: 1371px) {
  .main_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
  }
  .title_sec {
    margin-bottom: 75px;
  }
  .title_1 {
    font-family: Pretendard;
    font-size: 37px;
    font-weight: 600;
    color: #1f299c;
    text-align: center;
  }
  .title_2 {
    margin-top: 25px;
    font-size: 20px;
    color: #1f299c;
    text-align: center;
  }
  .info_sec {
    display: flex;
    justify-content: space-between;
    max-width: 1414px;
    width: 100%;
  }
  .info_img_div {
    width: 695px;
    height: 388px;
  }
  .info_des_div {
    width: 653px;
    height: 388px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .info_des_div2 {
    width: 653px;
    height: 388px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .info_button_div {
    display: flex;
    margin-top: 30px;
  }
  .info_button_div_mobile {
    display: none;
  }
  .blu_btn {
    width: 252px;
    height: 51px;
    background-color: #1f299c;
    border-radius: 5px;
    text-align: center;
    font-size: 17px;
    color: #ffffff;
    cursor: pointer;
    line-height: 51px;
  }
  .org_btn {
    width: 252px;
    height: 51px;
    background-color: #f15928;
    border-radius: 5px;
    text-align: center;
    font-size: 17px;
    color: #ffffff;
    cursor: pointer;
    line-height: 51px;
    margin-left: 20px;
  }
  .info_text_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 307px;
  }
  .info_text_div2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 307px;
  }
  .title_3 {
    color: #1f299c;
    font-size: 25px;
    font-weight: 600;
    padding-bottom: 25px;
  }
  .text_div {
    margin-top: 13px;
    display: flex;
    margin-bottom: 13px;
  }
  .text_div2 {
    display: flex;
  }
  .text_div3 {
    display: flex;
    margin-top: 13px;
    margin-bottom: 5px;
  }

  .text_mark1 {
    width: 20px;
    height: 2px;
    background-color: #f15928;
    margin-right: 8px;
    margin-top: 10px;
  }
  .text_mark2 {
    width: 20px;
    height: 2px;
    margin-right: 8px;
  }
  .text_mark3 {
    width: 34px;
    height: 5px;
    margin-right: 8px;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .text_mark_c {
    width: 4px;
    height: 4px;
    background-color: #282828;
    border-radius: 4px;
  }
  .text1 {
    font-family: Pretendard;
    font-size: 17px;
    color: #333333;
    font-weight: 500;
    width: 540px;
  }
  .text2 {
    font-family: Pretendard;
    font-size: 17px;
    color: #333333;
    font-weight: 500;
    width: 597px;
  }
  .text3 {
    font-family: Pretendard;
    font-size: 18px;
    color: #1f299c;
    font-weight: 500;
    width: 573px;
  }

  .text4 {
    font-family: Pretendard;
    font-size: 17px;
    color: #333333;
    font-weight: 500;
    width: 552px;
  }
  .PS {
    font-family: Pretendard;
    margin-top: 120px;
    margin-bottom: 115px;
    font-size: 25px;
  }
  .PS2 {
    font-family: Pretendard;
    margin-top: 120px;
    margin-bottom: 115px;
    font-size: 25px;
  }
  .PI {
    width: 1414px;
    background-color: #f8f8f8;
    padding-top: 62px;
    padding-bottom: 123px;
    margin-bottom: 198px;
    text-align: center;
  }
  .PI_IMG {
    width: 1211px;
    height: auto;
  }
}
@media all and (min-width: 881px) and (max-width: 1370px) {
  .main_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    min-height: 72vh;
  }
  .title_sec {
    margin-bottom: 57px;
  }
  .title_1 {
    font-family: Pretendard;
    font-size: 24px;
    font-weight: 600;
    color: #1f299c;
    text-align: center;
  }
  .title_2 {
    margin-top: 11px;
    font-size: 16px;
    color: #1f299c;
    text-align: center;
  }
  .info_sec {
    display: flex;
    justify-content: space-between;
    max-width: 903px;
    width: 100%;
  }
  .info_img_div {
    width: 445px;
    height: 248px;
  }
  .info_des_div {
    width: 408px;
    height: 248px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .info_des_div2 {
    width: 408px;
    height: 248px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .info_button_div {
    display: flex;
    margin-top: 20px;
  }
  .info_button_div_mobile {
    display: none;
  }
  .blu_btn {
    width: 160px;
    height: 35px;
    background-color: #1f299c;
    border-radius: 3px;
    text-align: center;
    font-size: 15px;
    color: #ffffff;
    cursor: pointer;
    line-height: 35px;
  }
  .org_btn {
    width: 160px;
    height: 35px;
    background-color: #f15928;
    border-radius: 3px;
    text-align: center;
    font-size: 15px;
    color: #ffffff;
    cursor: pointer;
    line-height: 35px;
    margin-left: 13px;
  }
  .info_text_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 193px;
  }
  .info_text_div2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 235px;
  }
  .title_3 {
    color: #1f299c;
    font-size: 19px;
    font-weight: 600;
    padding-bottom: 8px;
  }
  .text_div {
    padding-top: 8px;
    display: flex;
    padding-bottom: 8px;
  }
  .text_div2 {
    display: flex;
  }
  .text_div3 {
    display: flex;
    margin-top: 8px;
    margin-bottom: 2px;
  }

  .text_mark1 {
    width: 15px;
    height: 2px;
    background-color: #f15928;
    margin-right: 6px;
    margin-top: 8px;
  }
  .text_mark2 {
    width: 20px;
    height: 2px;
    margin-right: 1px;
  }
  .text_mark3 {
    width: 25px;
    height: 5px;
    margin-right: 6px;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .text_mark_c {
    width: 3px;
    height: 3px;
    background-color: #282828;
    border-radius: 4px;
  }
  .text1 {
    font-family: Pretendard;
    font-size: 13.5px;
    color: #333333;
    font-weight: 500;
    width: 385px;
  }
  .text2 {
    font-family: Pretendard;
    font-size: 13px;
    color: #333333;
    font-weight: 500;
    width: 390px;
  }
  .text3 {
    font-family: Pretendard;
    font-size: 15px;
    color: #1f299c;
    font-weight: 500;
    width: 385px;
  }

  .text4 {
    font-family: Pretendard;
    font-size: 13px;
    color: #333333;
    font-weight: 500;
    width: 552px;
  }
  .PS {
    font-family: Pretendard;
    margin-top: 70px;
    margin-bottom: 50px;
    font-size: 18px;
  }
  .PS2 {
    font-family: Pretendard;
    margin-top: 70px;
    margin-bottom: 50px;
    padding-top: 42px;
    font-size: 18px;
  }
  .PI {
    width: 903px;
    background-color: #f8f8f8;
    padding-top: 36px;
    padding-bottom: 37px;
    margin-bottom: 198px;
    text-align: center;
  }
  .PI_IMG {
    width: 717px;
    height: auto;
  }
}

@media all and (min-width: 300px) and (max-width: 880px) {
  .main_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 31px;
  }
  .title_sec {
    margin-bottom: 20px;
  }
  .title_1 {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    color: #1f299c;
    text-align: center;
  }
  .title_2 {
    margin-top: 11px;
    font-size: 14px;
    color: #1f299c;
    text-align: center;
  }
  .info_sec {
    display: flex;
    justify-content: space-between;
    max-width: 335px;
    width: 100%;
    flex-direction: column;
  }
  .info_img_div {
    width: 335px;
    height: 260px;
    margin-bottom: 20px;
  }
  .info_des_div {
    width: 335px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .info_des_div2 {
    width: 335px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media all and (min-width: 300px) and (max-width: 768px) {
    .info_button_div_mobile {
      display: flex !important;
      /* margin-top: 20px; */
      position: fixed;
      bottom: 0;
      height: 68px;
      width: 100%;
      background-color: white;
      border-top: 1px solid #ddd;
      align-items: center;
      justify-content: center;
    }
    .blu_btn_mobile {
      width: 160px;
      height: 50px;
      background-color: #1f299c;
      border-radius: 3px;
      text-align: center;
      font-size: 15px;
      color: #ffffff;
      cursor: pointer;
      line-height: 50px;
    }
    .org_btn_mobile {
      width: 160px;
      height: 50px;
      background-color: #f15928;
      border-radius: 3px;
      text-align: center;
      font-size: 15px;
      color: #ffffff;
      cursor: pointer;
      line-height: 50px;
      margin-left: 13px;
    }
    .info_button_div {
      display: none;
      margin-top: 20px;
    }
  }
  @media all and (min-width: 769px) and (max-width: 880px) {
    .info_button_div_mobile {
      display: none;
    }
    .info_button_div {
      display: flex;
      margin-top: 20px;
    }
    .blu_btn {
      width: 160px;
      height: 35px;
      background-color: #1f299c;
      border-radius: 3px;
      text-align: center;
      font-size: 15px;
      color: #ffffff;
      cursor: pointer;
      line-height: 35px;
    }
    .org_btn {
      width: 160px;
      height: 35px;
      background-color: #f15928;
      border-radius: 3px;
      text-align: center;
      font-size: 15px;
      color: #ffffff;
      cursor: pointer;
      line-height: 35px;
      margin-left: 13px;
    }
  }
  .info_text_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 193px; */
  }
  .info_text_div2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 235px; */
  }
  .title_3 {
    color: #1f299c;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 8px;
  }
  .text_div {
    padding-top: 8px;
    display: flex;
    padding-bottom: 8px;
  }
  .text_div2 {
    display: flex;
  }
  .text_div3 {
    display: flex;
    margin-top: 8px;
    margin-bottom: 2px;
  }

  .text_mark1 {
    width: 15px;
    height: 2px;
    background-color: #f15928;
    margin-right: 6px;
    margin-top: 8px;
  }
  .text_mark2 {
    width: 20px;
    height: 2px;
    margin-right: 1px;
  }
  .text_mark3 {
    width: 25px;
    height: 5px;
    margin-right: 6px;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .text_mark_c {
    width: 3px;
    height: 3px;
    background-color: #282828;
    border-radius: 4px;
  }
  .text1 {
    font-family: Pretendard;
    font-size: 14px;
    color: #333333;
    font-weight: 500;
    width: 385px;
  }
  .text2 {
    font-family: Pretendard;
    font-size: 14px;
    color: #333333;
    font-weight: 500;
    width: 390px;
  }
  .text3 {
    font-family: Pretendard;
    font-size: 15px;
    color: #1f299c;
    font-weight: 500;
    width: 385px;
  }

  .text4 {
    font-family: Pretendard;
    font-size: 14px;
    color: #333333;
    font-weight: 500;
    width: 552px;
  }
  .PS {
    font-family: Pretendard;
    margin-top: 60px;
    margin-bottom: 20px;
    font-size: 18px;
  }
  .PS2 {
    font-family: Pretendard;
    margin-top: 60px;
    margin-bottom: 20px;
    font-size: 18px;
  }
  .PI {
    width: 100%;
    background-color: #f8f8f8;
    padding-top: 36px;
    padding-bottom: 37px;
    margin-bottom: 198px;
    text-align: center;
  }
  .PI_IMG {
    width: 335px;
    height: auto;
  }
}
