* {
  border: none;
  padding: 0;
  margin: 0;
}

@media all and (min-width: 1024px) {

  .nav_sidebar,
  .newsletter_nav_sidebar {
    position: fixed;
    max-height: 789px;
    width: 58px;
    box-shadow: 0.4px 2px 7px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    bottom: 15px;
    left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    /* transition-property: max-height;
    transition-duration: 1s; */
    padding: 0 6px;
    z-index: 10;
  }

  /* .open {
    max-height: 641px;
    height: fit-content;
  } */
  .divider {
    height: 1px;
    width: 100%;
    background-color: #d1d1d1;
  }

  .top_btn {
    height: 80px;
    min-height: 80px;
    width: 100%;
  }

  .top_btn .list_btn {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 18px;
    background-color: #fff;
    /* border: 1px solid red; */
    border: none;
    cursor: pointer;
  }

  .top_btn .list_icon {
    width: 17px;
    height: 16px;
  }

  .top_btn .list_txt {
    height: 18px;
    font-family: Pretendard;
    font-size: 21px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;
    text-align: center;
    color: #1f299c;
  }

  .list_wrapper {
    height: 465px;
    max-height: 465px;
    width: 100%;
    /* padding: 5px 0; */
    position: relative;
    overflow: hidden;
    margin: 5px 0;
  }

  .list_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    position: absolute;
    transition-property: top;
    transition-duration: 0.5s;
  }

  .episode_btn {
    width: 100%;
    height: 42px;
    min-height: 42px;
    border-radius: 3px;
    background-color: #fff;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #909090;
    cursor: pointer;
    /* border: 1px solid black; */
  }

  /* .episode_btn:hover {
  border: 1px solid grey;
} */
  .active {
    background-color: #ededed;
    color: #1f299c;
  }

  .bot_btn {
    height: 53px;
    width: 100%;
    /* border: 1px solid red; */
    padding-top: 17px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .arrow_btn {
    background-color: #fff;
    border: none;
    cursor: pointer;
  }

  .arrow_img {
    width: 19px;
    height: 14px;
  }

  .content_area {
    /* border: 1px solid black; */
    width: 100%;
    height: 0px;
    margin: 0 6px;
    transition-property: height;
    transition-duration: 1s;
  }

  .open {
    height: 562px;
  }
}

/* tablet */
@media all and (min-width: 768px) and (max-width: 1023px) {

  .nav_sidebar,
  .newsletter_nav_sidebar {
    position: fixed;
    max-height: 419px;
    width: 50px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.24);
    background-color: #fff;
    bottom: 15px;
    left: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    /* transition-property: max-height;
    transition-duration: 1s; */
    padding: 0 5px;
    z-index: 10;
  }

  /* .open {
    max-height: 641px;
    height: fit-content;
  } */
  .divider {
    height: 1px;
    width: 100%;
    background-color: #d1d1d1;
  }

  .top_btn {
    height: 69.5px;
    min-height: 69.5px;
    width: 100%;
  }

  .top_btn .list_btn {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 9px;
    padding-bottom: 11.5px;
    background-color: #fff;
    /* border: 1px solid red; */
    border: none;
    cursor: pointer;
  }

  .top_btn .list_icon {
    width: 20px;
    height: 20px;
  }

  .top_btn .list_txt {
    height: 18px;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: -0.19px;
    text-align: center;
    color: #13249f;
  }

  .list_wrapper {
    height: 245px;
    max-height: 245px;
    width: 100%;
    /* padding: 5px 0; */
    position: relative;
    overflow: hidden;
    overflow-y: scroll;
    margin: 15px 0;
  }

  .list_wrapper::-webkit-scrollbar {
    display: none;
  }

  .list_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    position: absolute;
    transition-property: top;
    transition-duration: 0.5s;
  }

  .episode_btn {
    width: 100%;
    height: 36px;
    min-height: 36px;
    border-radius: 3px;
    background-color: #fff;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #909090;
    cursor: pointer;
    /* border: 1px solid black; */
  }

  /* .episode_btn:hover {
  border: 1px solid grey;
} */
  .active {
    background-color: #ededed;
    color: #1f299c;
  }

  .bot_btn {
    height: 49px;
    width: 100%;
    /* border: 1px solid red; */
    padding-top: 12.5px;
    padding-bottom: 11px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .arrow_btn {
    background-color: #fff;
    border: none;
    cursor: pointer;
  }

  .arrow_img {
    width: 18px;
    height: 15px;
  }

  .content_area {
    /* border: 1px solid black; */
    width: 100%;
    height: 0px;
    margin: 0 6px;
    transition-property: height;
    transition-duration: 1s;
  }

  .open {
    height: 562px;
  }
}

/* mobile */
@media all and (max-width: 767px) {
  .nav_sidebar {
    position: fixed;
    max-height: 372px;
    width: 52px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.24);
    background-color: #fff;
    bottom: 15px;
    left: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    /* transition-property: max-height;
    transition-duration: 1s; */
    padding: 0 5px;
    z-index: 10;
  }

  .newsletter_nav_sidebar {
    position: fixed;
    max-height: 372px;
    width: 52px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.24);
    background-color: #fff;
    bottom: 80px;
    left: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    /* transition-property: max-height;
    transition-duration: 1s; */
    padding: 0 5px;
    z-index: 10;
  }

  /* .open {
    max-height: 641px;
    height: fit-content;
  } */
  .divider {
    height: 1px;
    width: 100%;
    background-color: #d1d1d1;
  }

  .top_btn {
    height: 61.7px;
    min-height: 61.7px;
    width: 100%;
    z-index: 100;
  }

  .top_btn .list_btn {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 9px;
    padding-bottom: 11.5px;
    background-color: #fff;
    /* border: 1px solid red; */
    border: none;
    cursor: pointer;
  }

  .top_btn .list_icon {
    width: 20px;
    height: 20px;
  }

  .top_btn .list_txt {
    height: 18px;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: -0.19px;
    text-align: center;
    color: #13249f;
  }

  .list_wrapper {
    height: 214.3px;
    max-height: 214.3px;
    width: 100%;
    /* padding: 5px 0; */
    position: relative;
    overflow: hidden;
    margin: 15px 0;
  }

  .list_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    position: absolute;
    transition-property: top;
    transition-duration: 0.5s;
  }

  .episode_btn {
    width: 100%;
    height: 32px;
    min-height: 32px;
    border-radius: 3px;
    background-color: #fff;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #909090;
    cursor: pointer;
    /* border: 1px solid black; */
  }

  /* .episode_btn:hover {
  border: 1px solid grey;
} */
  .active {
    background-color: #ededed;
    color: #1f299c;
  }

  .bot_btn {
    height: 44px;
    width: 100%;
    /* border: 1px solid red; */
    padding-top: 9px;
    padding-bottom: 11px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .arrow_btn {
    background-color: #fff;
    border: none;
    cursor: pointer;
  }

  .arrow_img {
    width: 18px;
    height: 15px;
  }

  .content_area {
    /* border: 1px solid black; */
    width: 100%;
    height: 0px;
    margin: 0 6px;
    transition-property: height;
    transition-duration: 1s;
  }

  .open {
    height: 310.3px;
  }
}