.card_body {
  cursor: pointer;
}

/* PC (해상도 1024px)*/
@media all and (min-width: 1024px) {
  .card_body {
    width: 439px;
  }

  .card_box {
    width: 437px;
    height: 322px;
    border: 1px solid #c2c3cf;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
  }

  .favorite_button {
    position: absolute;
    width: 40px;
    height: 41px;
    border: 1px solid #bdbfd9;
    background-color: #f8f8f8;
    border-radius: 5px;
    right: 18px;
    top: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .favorite_img {
    width: 18px;
    height: 16px;
  }

  .card_img {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
  }

  .card_img:hover {
    transform: scale(1.1);
  }

  .card_title {
    font-family: Pretendard;
    margin-top: 24px;
    font-size: 19px;
    color: #1f299c;
    text-align: center;
    font-weight: 500;
  }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023px) {
  .card_body {
    width: 292px;
  }

  .card_box {
    width: 290px;
    height: 212px;
    border: 1px solid #c2c3cf;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
  }

  .favorite_button {
    position: absolute;
    width: 27px;
    height: 27px;
    border: 1px solid #bdbfd9;
    background-color: #f8f8f8;
    border-radius: 5px;
    right: 9px;
    top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .favorite_img {
    width: 10.9px;
    height: 9.6px;
  }

  .card_img {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
  }

  .card_img:hover {
    transform: scale(1.1);
  }

  .card_title {
    font-family: Pretendard;
    margin-top: 14px;
    font-size: 14px;
    color: #1f299c;
    text-align: center;
    font-weight: 500;
  }
}

@media all and (max-width: 767px) {
  .card_body {
    width: 333px;
  }

  .card_box {
    width: 331px;
    height: 244px;
    border: 1px solid #bebfcc;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
  }

  .favorite_button {
    position: absolute;
    width: 31px;
    height: 31px;
    border: 1px solid #bdbfd9;
    background-color: #f8f8f8;
    border-radius: 5px;
    right: 8px;
    top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .favorite_img {
    width: 12.5px;
    height: 11px;
  }

  .card_img {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
  }

  .card_img:hover {
    transform: scale(1.1);
  }

  .card_title {
    font-family: Pretendard;
    margin-top: 16px;
    font-size: 17px;
    color: #1f299c;
    text-align: center;
    font-weight: 500;
  }
}
