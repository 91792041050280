@media all and (min-width: 1024px) {
  .myInfo_wrapper {
    width: 100%;
    height: 100%;
    /* position: relative; */
    /* overflow: auto; */
  }

  .myInfo_container {
    width: 556px;
    margin-top: 82px;
    margin-left: 15%;
    /* position: absolute;
    top: 82px;
    left: 22%;
    transform: translateX(-22%); */
  }

  .myInfo_title {
    height: 30px;
    font-family: LibreBaskerville;
    font-size: 27px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
    margin-bottom: 36px;
  }

  .myInfo_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 28px;
    margin-bottom: 50px;
  }

  .myInfo_input_box {
    width: 530px;
    height: 74px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .myInfo_input_title {
    width: 100%;
    height: 16px;
    display: flex;
    align-items: center;
  }

  .orange_dot {
    width: 6px;
    height: 6px;
    background-color: #f15928;
    border-radius: 100%;
  }

  .input_title {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: normal;
    text-align: left;
    color: #2c34a0;
    margin-left: 9px;
  }

  .input_box {
    height: 50px;
  }

  .myInfo_btn_area {
    width: 529px;
  }

  .myInfo_btn_area>.myInfo_btn {
    width: 100%;
    height: 53px;
    background-color: #1f299c;
  }

  /* @media all and (min-width: 1023px) and (max-width: 1418px) {
    .myInfo_container {
      margin: 0 auto;
      margin-top: 82px;
    }
  } */
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .myInfo_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .myInfo_container {
    width: 417px;
    /* margin-top: 82px;
    margin-left: 194px; */
    position: absolute;
    top: 51.5px;
    /* left: 138.5px; */
    left: 42%;
    transform: translateX(-42%);
  }

  .myInfo_title {
    height: 19px;
    font-family: LibreBaskerville;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
    margin-bottom: 49px;
  }

  .myInfo_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 20px;
  }

  .myInfo_input_box {
    width: 100%;
    height: 61px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .myInfo_input_title {
    width: 100%;
    height: 13px;
    display: flex;
    align-items: center;
  }

  .orange_dot {
    width: 4px;
    height: 4px;
    background-color: #f15928;
    border-radius: 100%;
  }

  .input_title {
    font-family: Pretendard;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.85;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
    margin-left: 8px;
  }

  .input_box {
    height: 40px;
  }

  .myInfo_btn_area {
    width: 100%;
  }

  .myInfo_btn_area>.myInfo_btn {
    width: 100%;
    height: 53px;
    background-color: #1f299c;
  }
}

@media all and (max-width: 767px) {
  .myInfo_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .myInfo_container {
    width: 335px;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }

  .myInfo_title {
    height: 30px;
    font-family: LibreBaskerville;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
    margin-bottom: 38px;
  }

  .myInfo_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 20px;
  }

  .myInfo_input_box {
    width: 100%;
    height: 72px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .myInfo_input_title {
    width: 100%;
    height: 13px;
    display: flex;
    align-items: center;
  }

  .orange_dot {
    width: 4px;
    height: 4px;
    background-color: #f15928;
    border-radius: 100%;
  }

  .input_title {
    font-family: Pretendard;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: normal;
    text-align: left;
    color: #1f299c;
    margin-left: 9px;
  }

  .input_box {
    height: 48px;
  }

  .myInfo_btn_area {
    width: 100%;
  }

  .myInfo_btn_area>.myInfo_btn {
    width: 100%;
    height: 53px;
    background-color: #1f299c;
  }
}

.select>div {
  /* border: none !important;
  outline: none !important; */
  height: 100% !important;
  align-items: center;
}

.select>div input {
  height: 100% !important;
}